import React, { useState } from 'react';
import { Box, Typography, Grid, Avatar, useTheme, useMediaQuery, Paper, Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { User } from 'lucide-react';
import QuadrantCard from '../Dashboard/QuadrantCard';

export default function EmpathyMap({ empathyState, persona }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [painOpen, setPainOpen] = useState(false);
  const [gainOpen, setGainOpen] = useState(false);

  const handlePainOpen = () => setPainOpen(true);
  const handleGainOpen = () => setGainOpen(true);
  const handlePainClose = () => setPainOpen(false);
  const handleGainClose = () => setGainOpen(false);
  // console.log("empathyState : ", empathyState)
  return (
    <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
      <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
        Empathy Map
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Says || "No Data Available"} /> {/* SAYS */}
            </Grid>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Thinks || "No Data Available"} /> {/* THINKS */}
            </Grid>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Does || "No Data Available"} /> {/* DOES */}
            </Grid>
            <Grid item xs={12} md={6}>
              <QuadrantCard section={empathyState?.Feels || "No Data Available"} /> {/* FEELS */}
            </Grid>
          </Grid>

          {/* Pain Section with Dialog */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', bgcolor: 'secondary.light', color: 'whitesmoke' }}>
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom sx={{ color: "white" }}>
                    {empathyState?.Pain?.title || "Pain"} {/* PAIN */}
                  </Typography>
                  <Typography variant="body2" color="white" paragraph>
                    {empathyState?.Pain?.question || "No Pain Available"}
                  </Typography>
                  {empathyState?.Pain?.data?.length > 0 ? (
                    empathyState.Pain.data.slice(0, 3).map((item, i) => (
                      <Typography key={i} variant="body2" paragraph>
                        {item === 'None' ? "No Pain" : `• ${item.sentence || ''}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2" paragraph>
                      No Pain Available
                    </Typography>
                  )}

                  <Button size="small" sx={{ color: 'white' }} onClick={handlePainOpen}>
                    More
                  </Button>
                </CardContent>
              </Card>

              {/* Pain Dialog */}
              <Dialog open={painOpen} onClose={handlePainClose} fullWidth>
                <DialogTitle>{empathyState?.Pain?.title || "Pain"}</DialogTitle>
                <DialogContent dividers>
                  <Typography variant="body1" gutterBottom>
                    {empathyState?.Pain?.question || "No Pain Available"}
                  </Typography>
                  {empathyState?.Pain?.data?.length > 0 ? (
                    empathyState.Pain.data.map((item, i) => (
                      <Typography key={i} variant="body1" paragraph>
                        {item === 'None' ? "No Pain" : `• ${item.sentence || ''}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body1" paragraph>
                      No Pain Available
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handlePainClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </Grid>

            {/* Gain Section with Dialog */}
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', bgcolor: 'secondary.light', color: 'whitesmoke' }}>
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {empathyState?.Gain?.title || "Gain"} {/* GAIN */}
                  </Typography>
                  <Typography variant="body2" color="white" paragraph>
                    {empathyState?.Gain?.question || "No Gain Available"}
                  </Typography>
                  {empathyState?.Gain?.data?.length > 0 ? (
                    empathyState.Gain.data.slice(0, 3).map((item, i) => (
                      <Typography key={i} variant="body2" paragraph>
                        {item === 'None' ? "No Gain" : `• ${item.sentence || ''}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2" paragraph>
                      No Gain Available
                    </Typography>
                  )}

                  <Button size="small" sx={{ color: 'white' }} onClick={handleGainOpen}>
                    More
                  </Button>
                </CardContent>
              </Card>

              {/* Gain Dialog */}
              <Dialog open={gainOpen} onClose={handleGainClose} fullWidth>
                <DialogTitle>{empathyState?.Gain?.title || "Gain"}</DialogTitle>
                <DialogContent dividers>
                  <Typography variant="body1" gutterBottom>
                    {empathyState?.Gain?.question || "No Gain Available"}
                  </Typography>
                  {empathyState?.Gain?.data?.length > 0 ? (
                    empathyState.Gain.data.map((item, i) => (
                      <Typography key={i} variant="body1" paragraph>
                        {item === 'None' ? "No Gain" : `• ${item.sentence || ''}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body1" paragraph>
                      No Gain Available
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleGainClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>

        {/* Persona Section */}
        <Grid item xs={12} md={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ width: 80, height: 80, bgcolor: 'primary.light', mb: 2 }}>
              <User size={48} />
            </Avatar>
            <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
              WHO?
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              {persona?.Role}
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              {persona.Tech === '' ? '' : `Tech: ${persona.Tech}`}
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              {persona.Age === '' ? '' : `Age: ${persona.Age}`}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
