import React from 'react';
import { Grid } from '@mui/material';

const useCardGrid = (data, CardComponentType, themeCheck) => {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                display: 'flex',
                flexWrap: 'nowrap', // Forces cards to stay in one line
                // overflowX: 'auto', // Adds horizontal scroll for overflow
                paddingX: themeCheck ? 0 : 3,
            }}
        >
            {data.map((item, index) => (
                <Grid
                    item
                    key={index}
                    sx={{
                        flex: '0 0 auto', // Prevents cards from shrinking or growing
                        width: themeCheck ? 170 : 200, // Matches the card's width for consistency
                    }}
                >
                    <CardComponentType {...item} />
                </Grid>
            ))}
        </Grid>
    );
};

export const useCardGrid2 = (data, CardComponentType) => {
    return (
        <Grid container spacing={4} sx={{ marginY: { xs: 2, sm: 3 } }}>
            {data.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index} sx={{ marginY: { xs: 2, sm: 3, md: 3, lg: 2 }, marginLeft: { lg: 4 } }}>
                    <CardComponentType {...item} />
                </Grid>
            ))}
        </Grid>
    )
}

export default useCardGrid;