import { createContext, useContext, useState } from "react";

const FeedbackQContext = createContext();

export const FeedbackQProvider = ({ children }) => {
    const [themes, setThemes] = useState([]);
    const [themeAnalysis, setThemeAnalysis] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [loadingThemes, setLoadingThemes] = useState(true);
    const [loadingAnalysis, setLoadingAnalysis] = useState(true);
    const [themeError, setThemeError] = useState(null);
    const [analysisError, setAnalysisError] = useState(null);
    const [token, setToken] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [updateDataSource, setUpdateDataSource] = useState(false)
    const [ruleCheck, setRuleCheck] = useState(false) 
    const [openDrawer, setOpenDrawer] = useState(false);
    const [name, setName] = useState("Loading.....");
    const [isTyping, setIsTyping] = useState(false);
    const [searchResult, setSearchResult] = useState([]);

    const [dashboardCheck, setDashboardCheck] = useState(false);
    const [hasApps, setHasApps] = useState(false);
    const [ruleDisable, setRuleDisable] = useState(false)
    //loader
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(null);
    const [featureCheck, setFeatureCheck ] = useState(false)
    //progress
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [progressMessage, setProgressMessage] = useState('analyzing reviews');
    const [accType, setAccType] = useState("")
    //disable the button while analysis is running
    const [isDisabled, setIsDisabled] = useState(false);
    const [rulebookmark,setRulebookmark] = useState("");
    const [analysisCheck, setAnalysisCheck] = useState(false)
    const [duration, setDuration] = useState('Overall');
    const [source, setSource] = useState('Overall');
    const [category, setCategory] = useState('Overall');
    const [filterSource, setFilterSource] = useState('Monthly');
    const [filterNpxChart, setFilterNpxChart] = useState('Monthly');
    const [isUpdateCheck, setIsUpdateCheck] = useState(false);

    const getThemesByType = (type) => {
        return themes.filter(themeItem => themeItem.type === type);
    };

    return (
        <FeedbackQContext.Provider
            value={{
                featureCheck, 
                setFeatureCheck,
                isUpdateCheck, 
                setIsUpdateCheck,
                filterSource, 
                setFilterSource,
                filterNpxChart, 
                setFilterNpxChart,
                duration, 
                setDuration, 
                source, 
                setSource, 
                category, 
                setCategory,
                analysisCheck, 
                setAnalysisCheck,
                accType, 
                setAccType,
                ruleDisable, 
                setRuleDisable,
                openDrawer,
                setOpenDrawer,
                themes,
                themeAnalysis,
                selectedItem,
                loadingThemes,
                loadingAnalysis,
                themeError,
                analysisError,
                getThemesByType,
                setAnalysisError,
                setLoadingThemes,
                setLoadingAnalysis,
                setThemes,
                setThemeAnalysis,
                setThemeError,
                setSelectedItem,
                alertOpen,
                alertText,
                setAlertText,
                setAlertOpen,
                updateDataSource,
                setUpdateDataSource,
                dashboardCheck,
                setDashboardCheck,
                setLoading,
                loading,
                loadingData,
                setLoadingData,
                isAnalyzing,
                setIsAnalyzing,
                progressMessage,
                setProgressMessage,
                token,
                setToken,
                isDisabled,
                setIsDisabled,
                rulebookmark,
                setRulebookmark,
                ruleCheck,
                setRuleCheck,
                hasApps,
                setHasApps,
                name,
                setName,
                isTyping,
                setIsTyping,
                searchResult,
                setSearchResult
            }}
        >
            {children}
        </FeedbackQContext.Provider>
    );
}

export const useFeedbackQContext = () => useContext(FeedbackQContext);
