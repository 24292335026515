import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { checkDashboardName } from "../../api/API";
import { useFeedbackQContext } from "../../context/FeedbackQ";

const FormPopup = ({
    openForm,
    handleFormClose,
    newWorkspace,
    handleInputChange,
    handleCreateWorkspace,
    handleUpdateDashboard,
    isFormValid,
    dashboard,
}) => {
    const [nameError, setNameError] = useState("");
    const [isNameValid, setIsNameValid] = useState(false);
    const [showOtherField, setShowOtherField] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const { isAnalyzing,
        isUpdateCheck,
        setIsUpdateCheck
    } = useFeedbackQContext();
    const appTypes = [
        "AI", "AR/VR", "Art & Design", "Augmented Reality", "Auto & Vehicles", "Beauty", "Big Data", "Blockchain", "Books & Reference", "Business", "ChatGPT", "Comics", "Communication", "CRM", "Dating", "Developer Tools", "Education", "Entertainment", "ERP", "Events", "Finance", "Food & Drink", "Health & Fitness", "Healthcare SaaS", "House & Home", "HR & Payroll", "IoT", "Libraries & Demo", "Lifestyle", "Logistics", "Maps & Navigation", "Medical", "Music & Audio", "News & Magazines", "Parenting", "Personalization", "Photography", "Productivity", "Project Management", "SaaS", "Shopping", "Social", "Social Media", "Sports", "Tools", "Travel & Local", "Video Players & Editors", "Wear OS", "Weather"
    ];

    const checkDashboardNameExists = async (dashboard_name) => {
        try {
            const orgId = sessionStorage.getItem("orgId");
            const response = await checkDashboardName(dashboard_name, orgId);
            return response;
        } catch (error) {
            console.error("Error checking dashboard name:", error);
            return false;
        }
    };

    const handleNameBlur = async () => {
        if (newWorkspace.workspaceName.trim() === "") {
            setNameError("");
            setIsNameValid(false);
            return;
        }

        if (newWorkspace.workspaceName === "Default Dashboard") {
            setNameError("Default Dashboard name is not allowed ");
            setIsNameValid(false);
            return;
        }

        const nameExists = await checkDashboardNameExists(newWorkspace.workspaceName);

        if (nameExists) {
            setNameError("Dashboard name is already present.");
            setIsNameValid(false);
        } else {
            setNameError("");
            setIsNameValid(true);
        }
    };

    const handleAppTypeChange = (event, value) => {
        if (value === "Other") {
            setShowOtherField(true);
            handleInputChange({
                target: { name: "appType", value: "Other" },
            });
        } else {
            setShowOtherField(false);
            handleInputChange({
                target: { name: "appType", value },
            });
        }
    };
    useEffect(() => {
        const currentName = dashboard[0].dashboard_name;
        if (isUpdateCheck && dashboard.length > 0 && currentName === "Default Dashboard") {
            setIsUpdate(true);            
        }
    }, [dashboard, isUpdateCheck]);

    return (
        <Dialog open={openForm || isUpdate} onClose={handleFormClose} fullWidth maxWidth="sm">
            <DialogTitle>
                {isUpdate ? (
                    "Update Dashboard"
                ) : (
                    <>
                        Create New Dashboard
                        <IconButton
                            aria-label="close"
                            onClick={handleFormClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                )}
            </DialogTitle>
            
            <DialogContent>
                {/* Dashboard Name Field */}
                <TextField
                    margin="dense"
                    name="workspaceName"
                    label="Dashboard Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newWorkspace.workspaceName}
                    onChange={(e) => {
                        handleInputChange(e);
                        setIsNameValid(false);
                        setNameError("");
                    }}
                    onBlur={handleNameBlur}
                    required
                    error={!!nameError}
                    helperText={nameError}
                    InputProps={{
                        endAdornment: isNameValid && newWorkspace.workspaceName.trim() !== "" && (
                            <InputAdornment position="end">
                                <CheckIcon color="success" />
                            </InputAdornment>
                        ),
                    }}
                />

                {isNameValid && (
                    <Typography variant="body2" color="success.main">
                        Dashboard name is available!
                    </Typography>
                )}

                {/* Project Description Field */}
                <TextField
                    margin="dense"
                    name="description"
                    label="Project Description"
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    value={newWorkspace.description}
                    onChange={handleInputChange}
                    required
                />

                {/* App Type Field */}
                <Autocomplete
                    options={[...appTypes, "Other"]}
                    getOptionLabel={(option) => option}
                    filterOptions={(options, state) => {
                        const filtered = options.filter((opt) =>
                            opt.toLowerCase().includes(state.inputValue.toLowerCase())
                        );
                        if (!filtered.includes("Other")) {
                            filtered.push("Other");
                        }
                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="App Type" margin="dense" required fullWidth />
                    )}
                    onChange={handleAppTypeChange}
                />

                {showOtherField && (
                    <TextField
                        margin="dense"
                        name="otherAppType"
                        label="Specify App Type"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newWorkspace.otherAppType || ""}
                        onChange={(e) =>
                            handleInputChange({
                                target: { name: "otherAppType", value: e.target.value },
                            })
                        }
                        required
                    />
                )}

                {/* Project Features Field */}
                <TextField
                    margin="dense"
                    name="features"
                    label="Project Features"
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    value={newWorkspace.features}
                    onChange={handleInputChange}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFormClose} color="primary" disabled={isUpdate}>
                    Cancel
                </Button>
                {isUpdate ? (
                    <Button
                        onClick={handleUpdateDashboard}
                        color="primary"
                        disabled={!isFormValid() || !!nameError }
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        onClick={handleCreateWorkspace}
                        color="primary"
                        disabled={!isFormValid() || !!nameError }
                    >
                        Create
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default FormPopup;
