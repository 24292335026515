import { Card, CardContent, Typography, Box } from "@mui/material";

const CardComponent = ({ title, value, percentage }) => {
  return (
    <Card
      className="shadow-lg"
      sx={{
        borderRadius: 2,
        padding: 1,
        maxWidth: 200,
        height: 180, // Adjust the height to give extra space
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: 2,
        padding: 1,
      }}
    >
      <CardContent sx={{ paddingBottom: "16px !important" }}> {/* Ensure content has bottom padding */}
        <Typography variant="h6" className="font-bold text-gray-700" >
          {title}
        </Typography>
        <Box className="mt-4">
          <Typography variant="h4" className="font-bold text-blue-600">
            {value}
          </Typography>
          <Typography
            variant="body2"
            className={`mt-2 ${percentage >= 0 ? "text-green-600" : "text-red-600"
              }`}
          >
            {percentage >= 0 ? `+${percentage}%` : `${percentage}%`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
