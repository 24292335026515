import {
    Box,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TablePagination,
    Avatar,
    Typography,
    Tooltip
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import featureIcon from '../../../assets/icons/gear.png';
import bugIcon from '../../../assets/icons/bugs.png';
import appreciationIcon from '../../../assets/icons/appreciation.png';
import questionIcon from '../../../assets/icons/question.png';
import ThemeListChart from '../ThemeListChart';

const ThemeTableSection = ({
    paginatedData = [],
    handleOpenDrawer,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount
}) => {
    // console.log(" paginatedData: ", paginatedData);

    return (
        <Box
            className="bg-white border border-gray-300 rounded-lg p-6 mt-0 w-full"
            sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}
        >
            <TableContainer>
                <Table>
                    <TableBody>
                        {paginatedData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <Box className="flex flex-col items-center">
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            className="animate-fade-in-out"
                                            paddingY={4}
                                        >
                                            No themes available
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            paginatedData.map((item, index) => {
                                const chartData = [["Date", "Review Count"]];
                                if (item.last_month_each_day_review_count) {
                                    item.last_month_each_day_review_count.forEach(day => {
                                        chartData.push([day.date, day.count]);
                                    });
                                }

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        onClick={() => handleOpenDrawer(item)}
                                        sx={{
                                            cursor: 'pointer',
                                            transition: 'background-color 0.2s ease',
                                            '&:hover': { backgroundColor: '#f9f9f9' }
                                        }}
                                    >
                                        <TableCell sx={{ paddingX: 1.5, width: '5%' }}>
                                            <Avatar
                                                alt="Theme type icons"
                                                src={
                                                    item.type === 'Bug Report'
                                                        ? bugIcon
                                                        : item.type === 'Appreciation'
                                                            ? appreciationIcon
                                                            : item.type === 'Question'
                                                                ? questionIcon
                                                                : featureIcon
                                                }
                                                sx={{ width: 32, height: 32 }}
                                            />
                                        </TableCell>
                                        <TableCell className="truncate" sx={{ maxWidth: 200 }}>
                                            <Tooltip title={item.title} arrow>
                                                <Typography noWrap>{item.title}</Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 500 }}>
                                            {item.reviews_count}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 500 }}>
                                            {item.reviews_percentage + '%'}
                                        </TableCell>
                                        <TableCell align="right" sx={{ width: 32, height: 32 }} >
                                            <ThemeListChart data={chartData} chartId={`chart-${index}`} />

                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box className="flex justify-center mt-4">
                {paginatedData.length !== 0 && (
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20, 25]}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                        sx={{
                            '& .MuiTablePagination-actions': {
                                display: 'flex',
                                alignItems: 'center',
                            },
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input': {
                                fontSize: '0.875rem',
                            }
                        }}
                    />
                )}
            </Box>
        </Box>
    )
};

export default ThemeTableSection;
