import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useState } from 'react';
import { useFeedbackQContext } from '../../context/FeedbackQ';

export default function FullWidthMUILineChart({ months = [], monthlyNPSData = {} }) {
  const { filterSource, setFilterSource } = useFeedbackQContext();

  // Handle filter change and dynamically update X axis title
  const handleFilterChange = (event) => {
    setFilterSource(event.target.value);
  };

  const {
    play_store = [],
    app_store = [],
    csv = [],
    api = [],
    g2 = []
  } = monthlyNPSData;

  // X-axis title based on the selected filter
  const xAxisTitle = {
    Daily: 'Days',
    Weekly: 'Weeks',
    Monthly: 'Months',
    Yearly: 'Years'
  }[filterSource] || 'Time';  // Fallback title

  return (
    <Box className="w-full max-w-8xl mx-auto p-4">
      <Paper elevation={3} className="p-6">
        {/* Container for title and filter */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
            APP Performance Chart
          </Typography>
          {/* Dropdown for filter, aligned to the right */}
          <FormControl
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              boxShadow: 2,
              borderRadius: 1,
              minWidth: 140,
            }}
            size="small"
          >
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              value={filterSource}
              label="Filter"
              onChange={handleFilterChange}
            >
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Line Chart Container */}
        <Box className="w-full h-[400px]">
          <LineChart
            xAxis={[
              {
                data: months,
                scaleType: 'band',
                label: xAxisTitle,  // Dynamic X-axis title
                labelProps: { fontWeight: 'bold', fontSize: 14, color: '#4a4a4a' }
              }
            ]}
            yAxis={[
              {
                label: 'NPS Score',  // Y-axis title
                labelProps: { fontWeight: 'bold', fontSize: 14, color: '#4a4a4a' }
              }
            ]}
            series={[
              {
                data: csv,
                label: 'CSV',
                color: 'rgba(255, 99, 132, 1)',
                lineWidth: 2,
              },
              {
                data: api,
                label: 'API',
                color: 'rgba(53, 162, 235, 1)',
                lineWidth: 2,
              },
              {
                data: play_store,
                label: 'Play Store',
                color: 'rgba(255, 206, 86, 1)',
                lineWidth: 2,
              },
              {
                data: app_store,
                label: 'App Store',
                color: 'rgba(75, 192, 192, 1)',
                lineWidth: 2,
              },
              {
                data: g2,
                label: 'G2',
                color: 'rgba(100, 139, 2, 1)',
                lineWidth: 2,
              }
            ]}
            height={400}
            linechartprops={{
              grid: { strokeDasharray: '4' },  // Dashed grid lines for better aesthetics
              curveType: 'monotoneX',  // Smoother line
              lineWidth: 2,
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
}
