import React, { useState, useRef } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  CheckCircleOutlineOutlined,
  HighlightOffOutlined,
  ArrowBack,
} from "@mui/icons-material";
import Examples from "./Idexamples";
import { validateAppStoreId } from "./Idvalidator";
import {
  analyzeReviews,
  fetchPlayStoreREviews,
  validatePlayStoreId,
} from "../api/API";

import { useFeedbackQContext } from "../context/FeedbackQ";
import { useNotification } from "../context/Notification";


const AppForm = ({ sourceName, sourceDescription, example, closeDrawer }) => {
  const [dropdownDisabled, setDropdownDisabled] = useState(false);
  const [appId, setAppId] = useState("");
  const [appIdValid, setAppIdValid] = useState(null);
  const [hasBlurred, setHasBlurred] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const validationTimeout = useRef(null);

  const {
    setUpdateDataSource,
    setLoadingData,
    setLoading,
    setIsAnalyzing,
    setProgressMessage,
    setIsDisabled,
    accType,
    analysisCheck,
    setAnalysisCheck,
  } = useFeedbackQContext();
  const { showNotification } = useNotification();
  // const handleCheckboxChange = (event) => {
  //   setDropdownDisabled(event.target.checked);
  //   if (event.target.checked) {
  //     setReviewType("last 1000 reviews");
  //   } else {
  //     setReviewType("");
  //   }
  // };

  const handleAppIdChange = (event) => {
    setAppId(event.target.value);
    setAppIdValid(null);
    setHasBlurred(false);

    if (validationTimeout.current) {
      clearTimeout(validationTimeout.current);
    }
  };

  const handleAppIdBlur = () => {
    setHasBlurred(true);

    validationTimeout.current = setTimeout(async () => {
      if (appId.trim() === "") {
        setAppIdValid(null);
        return;
      }

      let isValid = false;
      if (example === "AppStore") {
        isValid = await validateAppStoreId(appId);
      } else if (example === "PlayStore") {
        isValid = await validatePlayStoreId(appId);
      }
      setAppIdValid(isValid);
    }, 1000);
  };

  const renderEndAdornment = () => {
    if (appId && hasBlurred && appIdValid !== null) {
      if (appIdValid) {
        return (
          <InputAdornment position="end">
            <IconButton size="small">
              <CheckCircleOutlineOutlined style={{ color: "green" }} />
            </IconButton>
          </InputAdornment>
        );
      } else {
        return (
          <InputAdornment position="end">
            <IconButton size="small">
              <HighlightOffOutlined style={{ color: "red" }} />
            </IconButton>
          </InputAdornment>
        );
      }
    }
    return null;
  };

  const handleFetchReviews = async () => {
    if (!appIdValid) {
      return;
    }

    const type = reviewType || "last 80 reviews"; 
    try {

      closeDrawer();
      // setLoading(true);
      // setLoadingData("Fetching Reviews from play store. Please wait..");

      const dashboard_id = localStorage.getItem('dashboardId')
      const orgId = sessionStorage.getItem('orgId')
      
      const userId = sessionStorage.getItem('userId')
      // Perform the background operations
      const data = await fetchPlayStoreREviews(appId, type, dashboard_id, orgId, userId);
      // console.log("Fetched reviews:", data);

      setUpdateDataSource(true);

      //loader
      // setLoading(false);
      const message = data.data.message;
      if (message === "data_points_exhausted") {
        showNotification("error", "data points exhausted");
      } else if (message === "app_source_exhausted") {
        showNotification("error", "app source exhausted");
      } else if (message === "exhaust_in_between") {
        showNotification(
          "warning",
          `App created/updated. Data points exhausted and only ${data.data.insert_count} reviews are fetched and analyzing`
        );
      } else {
        showNotification(
          "success",
          `App created/updated. ${data.data.insert_count} Reviews fetched and analyzing`
        );
      }

      setIsDisabled(true);

      if (message === "exhaust_in_between" || message === "reviews_fetched") {
        setIsAnalyzing(true);
        setProgressMessage(
          "Analysing reviews fetched from play store. Please wait..."
        );
        const analyzeData = await analyzeReviews(dashboard_id, orgId, userId);
        const analysisMessage = analyzeData.data.message;
        if (analysisMessage === 'token_exhausted') {
          showNotification(
            "error",
            "Token exhausted. Please refresh the tokens"
          );
        }
        else {
          setAnalysisCheck(!analysisCheck)
          showNotification(
            "success",
            "Analysis done"
          );
        }
      }





    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      // Ensure that this is always executed
      setIsAnalyzing(false);
      setIsDisabled(false);
      // console.log("Analysis completed. Progress completed from play store")
    }
  };
  //end of new code

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        backgroundColor: "white",
        boxShadow: 2,
        maxWidth: 700,
        margin: "80px auto",

        position: "relative",
      }}
    >
      <IconButton
        onClick={closeDrawer}
        sx={{ position: "absolute", top: 16, left: 16 }}
      >
        <ArrowBack />
      </IconButton>
      <Typography
        variant="h5"
        component="div"
        sx={{ fontWeight: "bold", mb: 1, textAlign: "center" }}
      >
        {sourceName}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
        {sourceDescription}
      </Typography>
      <TextField
        fullWidth
        label="App ID"
        variant="outlined"
        sx={{ mb: 1 }}
        value={appId}
        onChange={handleAppIdChange}
        onBlur={handleAppIdBlur}
        error={hasBlurred && appIdValid === false && appId.trim() !== ""}
        helperText={
          hasBlurred && appIdValid === false && appId.trim() !== ""
            ? "Invalid App ID"
            : ""
        }
        InputProps={{
          endAdornment: renderEndAdornment(),
        }}
      />
      <Typography
        variant="body2"
        sx={{ mb: 2, color: "gray" }}
        dangerouslySetInnerHTML={{ __html: `e.g. : ${Examples[example]}` }}
      />
      {accType === "trail" ? (
          <Typography variant="body1" sx={{ mx: 4 }}>
            Only the last 80 reviews are available in the TRIAL account.
          </Typography>
        ) : ( 
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FormControl sx={{ minWidth: 280 }} variant="outlined">
              <InputLabel>Review by Days</InputLabel>
              <Select
                label="Review by Days"
                disabled={dropdownDisabled}
                value={reviewType}
                onChange={(e) => setReviewType(e.target.value)}
              >
                <MenuItem value="last 7 days">Last 7 days</MenuItem>
                <MenuItem value="last 30 days">Last 30 days</MenuItem>
                <MenuItem value="last 90 days">Last 90 days</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" sx={{ mx: 4 }}>
              or
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 0 }}>
              <FormControl sx={{ minWidth: 280 }} variant="outlined">
                <InputLabel>Review by Count</InputLabel>
                <Select
                  label="Review by Count"
                  disabled={dropdownDisabled}
                  value={reviewType}
                  onChange={(e) => setReviewType(e.target.value)}
                >
                  <MenuItem value="last 100 reviews">Last 100 reviews</MenuItem>
                  <MenuItem value="last 200 reviews">Last 200 reviews</MenuItem>
                  <MenuItem value="last 500 reviews">Last 500 reviews</MenuItem>
                  <MenuItem value="last 1000 reviews">Last 1000 reviews</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}


      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 20, mt: 3 }}>
        {/* <Button variant="outlined">+ Add another app</Button> */}
        <Button
          variant="contained"
          onClick={handleFetchReviews}
          disabled={appIdValid === null || !appIdValid}
        >
          Fetch reviews
        </Button>
      </Box>
    </Box>
  );
};

export default AppForm;
