import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import CreateCollectFeedbackCard from "./CreateCollectFeedbackCard";
import { Link } from "react-router-dom";
import FieldMappingPopup from "./CsvPopup";
// import axios from 'axios';
import { fetchCsvReviews } from "../api/API";
import DatasourceTable from "./SourceData/DatasourceTable";
import { useFeedbackQContext } from "../context/FeedbackQ";
import ReviewsLoader from './Loaders/FetchingReviewsLoader'; //loader
import AnalyzeReviewsProgress from './Loaders/AnalyzeReviewsProgress'; //progress
import DataSourceDrawer from "./SourceData/DataSourceDrawer";


const CreateOrCollectFeedback = () => {
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [csvHeaders, setCsvHeaders] = React.useState([]);
    const [targetFields, setTargetFields] = React.useState([]);
    const [mappedFields, setMappedFields] = React.useState({});
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerContent, setDrawerContent] = React.useState({});
    const fileInputRef = React.useRef(null);

    const {
        setAlertOpen,
        loading,
        isAnalyzing,
        setLoading,
        isDisabled,
        isUpdateCheck,
        name,
        setIsUpdateCheck,
    } = useFeedbackQContext();

    React.useEffect(() => {
        if(name === 'Default Dashboard'){
            setIsUpdateCheck(true)
        }
    })

    const handleFieldChange = (csvField, targetField) => {
        setMappedFields((prev) => {
            let updatedFields = { ...prev };

            if (csvField && targetField) {
                updatedFields[csvField] = targetField;
            } else if (csvField) {
                updatedFields[csvField] = "";
            } else if (targetField) {
                const csvKey = Object.keys(updatedFields).find(
                    (key) => updatedFields[key] === ""
                );
                if (csvKey) {
                    updatedFields[csvKey] = targetField;
                }
            }
            return updatedFields;
        });
    };

    const handleFieldDelete = (csvField) => {
        setMappedFields((prev) => {
            const updatedFields = { ...prev };
            delete updatedFields[csvField];
            return updatedFields;
        });
    };

    const handleSave = () => {
        // console.log('Mapped Fields:', mappedFields);
        setPopupOpen(false);
        setAlertOpen(true);
        setLoading(true); //loader
    };

    const isCardDisabled = loading || isAnalyzing;
    const disabledMessage = loading
        ? "Fetching reviews, please wait..."
        : isAnalyzing
        ? "Analyzing reviews"
        : "";

    const handleUploadCSVClick = () => {
        fileInputRef.current.value = ""; // Reset file input value
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            try {
                const data = await fetchCsvReviews(formData);
                setCsvHeaders(data.columns);
                setMappedFields(data.matched_fields);
                setTargetFields(data.available_target_fields);
                setPopupOpen(true);
            } catch (error) {
                console.error("Error uploading CSV file:", error);
            } finally {
                setLoading(false); // Stop loading
            }
        }
    };

    const handlePopupClose = () => {
        setPopupOpen(false);
    };

    const handleCancel = () => {
        setCsvHeaders([]);
        setTargetFields([]);
        setMappedFields({});
        setPopupOpen(false);
    };

    const handleOpenDrawer = (content) => {
        setDrawerContent(content);
        setDrawerOpen(true);
    };

    return (
        <div
            style={{
                width: '100%',
                maxWidth: '1260px',
                height: '100%',
                color: 'black',
                margin: '0 auto',
                p: { xs: 2, sm: 3, md: 4 },
                boxSizing: 'border-box',
                mt: { xs: 2, md: 4 }, 
                mb: { xs: 2, md: 4 }
            }}
        >
            {/* <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 1.1,
                    backgroundColor: "#37517e", // Light background color
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for depth
                    mb: 2, // Margin-bottom to separate from other content
                }}
            >
                <Typography
                    variant="h4"
                    sx={{ marginLeft: "50px", backgroundColor: "#37517e", color: "#fff" }}
                    gutterBottom
                >
                    Create or Collect Feedback
                </Typography>
             </Box> */}
            {/* loader */}
            {/* {loading && <ReviewsLoader message="Fetching reviews, please wait..." />} */}
            <Typography 
                variant="body1" 
                sx={{ 
                    mb: 2,
                    fontSize: {  xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                    mt: 2,
                    textAlign: 'justify',
                }} 
                gutterBottom
            >
                Get started by connecting sources to sync data and generate feedback
                from surveys, emails, SMS, reviews & ratings, and social media to
                websites, apps, CRMs, ticketing systems, collaboration workspaces, and
                more.
            </Typography>
            <Typography
                variant="h5"
                gutterBottom
                style={{ 
                    marginTop: "40px", 
                    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                    fontWeight: 600,
                }}
            >
                Review and Rating
            </Typography>
            <Box
                style={{ 
                    marginTop: "30px", 
                    padding: { xs: '0 4px', sm: '0 12px', md: '0 50px' },
                }}
            >
                <Grid container spacing={2} justifyContent='flex-start'>
                    <Grid item xs={12} sm={6} md={3}>
                        <CreateCollectFeedbackCard
                            iconType="csv"
                            title="Upload CSV"
                            description="Analyze data from a CSV file."
                            onClick={handleUploadCSVClick}
                            disabled={isCardDisabled}
                            disabledMessage={disabledMessage}
                            cardActive={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CreateCollectFeedbackCard
                            iconType="googlePlay"
                            title="Google Play"
                            description="Connect to your app on Play Store."
                            disabled={isCardDisabled}
                            disabledMessage={disabledMessage}
                            onClick={()=> handleOpenDrawer({
                                sourceName: "Google Play Store",
                                sourceDescription: "Connect to your app on Google Play Store.",
                                example: 'PlayStore',
                                isApI: false
                            })}
                            cardActive={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CreateCollectFeedbackCard
                            iconType="api"
                            title="API"
                            description="Connect your api."
                            disabled={isCardDisabled}
                            disabledMessage={disabledMessage}
                            onClick={()=> handleOpenDrawer({
                                sourceName: "API",
                                sourceDescription: "Connect your api.",
                                example: 'API',
                                isApI: true
                            })}
                            cardActive={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {/* <Link to="/source/appstore" style={{ textDecoration: "none", color: "inherit" }}> */}
                        <CreateCollectFeedbackCard
                            iconType="appStore"
                            title="App Store"
                            description="Connect to your app on App Store."
                            disabled={true}
                        />
                        {/* </Link> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CreateCollectFeedbackCard
                            iconType="g2"
                            title="G2"
                            description="Connect to your app on G2."
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid item sm={12} sx={{ marginTop: 10 }}>
                    <DatasourceTable />
                </Grid>
            </Box>

            <FieldMappingPopup
                open={popupOpen}
                onClose={handlePopupClose}
                csvHeaders={csvHeaders}
                targetFields={targetFields}
                onFieldChange={handleFieldChange}
                onSave={handleSave}
                mappedFields={mappedFields}
                onFieldDelete={handleFieldDelete}
                onCancel={handleCancel}
                setMappedFields={setMappedFields}
            />
            <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            <DataSourceDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                sourceName={drawerContent.sourceName}
                sourceDescription={drawerContent.sourceDescription}
                example={drawerContent.example}
                isAPI={drawerContent?.isAPI}
            />
        </div>
    );
};

export default CreateOrCollectFeedback;
