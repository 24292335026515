import React, { createContext, useContext, useState } from "react";

// Create the context
const LoaderContext = createContext();

// Custom hook to use the Loader Context
export const useLoader = () => useContext(LoaderContext);

// Provider component
export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    // Function to trigger the loader
    const showLoader = (msg) => {
        setLoading(true);
        setMessage(msg);
    };

    // Function to hide the loader
    const hideLoader = () => {
        setLoading(false);
        setMessage("");
    };

    return (
        <LoaderContext.Provider value={{ loading, message, showLoader, hideLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};
