import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LockResetIcon from '@mui/icons-material/LockReset';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Check if the user is an organization admin
  const rawIsOrgAdmin = sessionStorage.getItem('is_org_admin');

  // Ensure rawIsOrgAdmin is a valid JSON string before parsing
  let isOrgAdmin = false;
  if (rawIsOrgAdmin) {
    try {
      isOrgAdmin = JSON.parse(rawIsOrgAdmin);
    } catch (error) {
      isOrgAdmin = false; // Fallback to a default value
    }
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    let BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      BASE_URL = 'https://insightq.ai';
    }
    window.location.href = `${BASE_URL}/reset-password`;
  };

  return (
    <>
      <IconButton sx={{ color: 'black', marginLeft: '5px' }} onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ borderRadius: "50%" }}
      >
        <MenuItem onClick={handleClose}>
          <Box onClick={handleReset}>
            <LockResetIcon sx={{ marginRight: '10px' }} />
            Reset Password
          </Box>
        </MenuItem>

        {isOrgAdmin && (
          <MenuItem onClick={handleClose}>
            <Link to={"/manage-user"}>
              <ManageAccountsIcon sx={{ marginRight: '10px' }} />
              Manage User
            </Link>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default SettingsMenu;
