import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination, Pagination } from '@mui/material';
import { Box } from '@mui/material';
import { Apple, Android } from '@mui/icons-material';
import ReviewDrawer from './ReviewDrawer';
import { fetchReviewsByThemeID } from '../../api/API';
import { useFeedbackQContext } from '../../context/FeedbackQ';
import csvIcon from '../../assets/icons/csv-icon.png';
import apiIcon from '../../assets/icons/api-icon.png';
import playstoreIcon from '../../assets/icons/game.png';
import appstoreIcon from '../../assets/icons/appstore.png';
import unknownIcon from '../../assets/icons/unknown-mail.png';

const ReviewUnderTheme = ({ setDashboardData }) => {
    const [page, setPage] = useState(1);
    const [reviews, setReviews] = useState([]); 
    const [selectedReview, setSelectedReview] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const rowsPerPage = 10;
    const { selectedItem, duration, source, category, filterSource, filterNpxChart } = useFeedbackQContext();
    useEffect(()=>{
        const fetchReviews = async()=>{
            try {
                const response = await fetchReviewsByThemeID(selectedItem.id, page, rowsPerPage, duration, source, category, filterNpxChart, filterSource)
                // console.log(response)
                if (response) {
                    const { reviews: fetchedReviews, pagination, nps_data } = response;
                    setReviews(fetchedReviews);
                    setTotalPages(pagination.total_pages);
                    // console.log("nps_data :: ", nps_data )
                    setDashboardData(nps_data)
                }
            } catch (error) {
                console.error('error occured while getting reviews: ',error);
            }
        }
        fetchReviews()
    }, [selectedItem.id, page, duration, source, category, filterSource, filterNpxChart])

    const handleOpenDrawer = (review) => {
        setSelectedReview(review);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedReview(null);
    };

    return (
        <Box className='my-6 p-4'>
            <TableContainer component={Paper} className='shadow-lg'>
                <Table>
                    <TableBody>
                        {reviews.slice(0, rowsPerPage).map((review) => (
                            <TableRow key={review.id} hover onClick={()=> handleOpenDrawer(review)} >
                                <TableCell>
                                    {
                                        review.source === 'app_store' ? 
                                            <img src={appstoreIcon} className='w-6 h-6' alt='app store icon' />
                                        : review.source === 'play_store' ?
                                            <img src={playstoreIcon} className='w-6 h-6' alt='play store icon' />
                                        : review.source === 'csv' ?
                                            <img src={csvIcon} className='w-6 h-6' alt='csv icon' />
                                        : review.source === 'api' ?
                                            <img src={apiIcon} alt='api icon' className='w-6 h-6' />
                                        : <img src={unknownIcon} alt='unknown source' className='w-6 h-6' />
                                    }
                                </TableCell>
                                <TableCell sx={{ maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{review.review}</TableCell>
                                <TableCell sx={{textAlign: 'center'}}>{review.user}</TableCell>
                                <TableCell>{review.date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* { reviews.length >= 10 &&( */}
            { totalPages >1 &&(
                <Pagination 
                    count={totalPages}
                    page={page}
                    onChange={(e, newPage) => setPage(newPage)}
                    color="secondary"
                    className='mt-3'
                />
            )} 
            { selectedReview && (
                <ReviewDrawer open={drawerOpen} onClose={handleCloseDrawer} review={selectedReview} />
            )}
            
        </Box>
    );
};

export default ReviewUnderTheme;
