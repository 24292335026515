import { Drawer } from "@mui/material"
import CreateRule from "./CreateRule"

const RuleTemplateDrawer = ({ open, onClose, templateID, editTemplateRule, ruleId, createRule })=>{
    return(
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '70%'
                }
            }}
        >
            <CreateRule templateID={templateID} editTemplateRule={editTemplateRule} id={ruleId} createRule={createRule}/>
        </Drawer>
    )
}

export default RuleTemplateDrawer;