import axios from "axios";

const api = axios.create({
  // baseURL: 'https://feedbackq-service.insightq.ai',
  baseURL: process.env.REACT_APP_APIBASEURL,
  // withCredentials: true,
})

export const handleApiError = (error, navigate) => {
  const statusCode = error.response?.status || 500;
  const message =
    error.response?.data?.message ||
    "An internal server error occurred. Please try again later.";
  navigate("/error", { state: { statusCode, message } });
};

// Add a request interceptor to include the auth token in each request
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("auth_token");
    const orgId = sessionStorage.getItem("orgId");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["orgId"] = orgId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchThemeAndAnalysis = async (dashboard_id) => {
  try {
    const requests = [
      api.get(`/api/themes?dashboard_id=${dashboard_id}`),
      api.get(`/themes/display-themes?dashboard_id=${dashboard_id}`),
    ];

    const [themesResponse, analysisResponse] = await axios.all(requests);
    return {
      themes: themesResponse.data,
      themeAnalysis: analysisResponse.data,
    };
  } catch (error) {
    throw new Error(`Error occured while fetching data: ${error.message}`);
  }
};

export const fetchThemeDetailes = async (theme_id, dashboard_id) => {
  try {
    const themeDetail = await api.get("/theme-details/themes2", {
      params: { theme_id: theme_id },
    });
    // console.log("theme details :::  ", themeDetail)
    const themeDetailCharts = await api.get(`/feedback-events/reviewCount?dashboard_id=${dashboard_id}`, {
      params: { theme_id: theme_id },
    });
    const [themeDetailResponse, themeDetailChartResponse] = await Promise.all([
      themeDetail,
      themeDetailCharts,
    ]);
    return {
      themeDetails: themeDetailResponse.data,
      themeDetailCharts: themeDetailChartResponse.data,
    };
  } catch (error) {
    throw new Error(`Error occured while fetching data: ${error.message}`);
  }
};

export const fetchReviewsByThemeID = async (themeID, page, limit, duration, source, review_category_type, nps_filter, source_filter) => {
  try {
    const response = await api.get("/themesReviewDetails/reviews", {
      params: { themeID, duration, source, review_category_type, nps_filter, source_filter, page, limit },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occured while fetching reviews: ${error}`);
  }
};

export const createDashboard = async (workspace_name, description, feature, app_type, user_id,  org_id) => {
  try {
    const response = await api.post("/dashboard/create-dashboards", { dashboard_name: workspace_name, description, org_id, feature, app_type, user_id, update_check: false, feature_created: false });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while creating dashboard: ${error}`);
  }
};

export const fetchDashboards = async (orgId) => {
  try {
    const response = await api.get(`/dashboard/get-dashboards?org_id=${orgId}`);
    return response.data.dashboards;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching dashboards: ${error.message}`
    );
  }
};

export const getOrgData = async (orgId) => {
  try {
    const response = await api.get("/playstore/get-org-info", { params: { orgId: orgId } });
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching tokem details: ${error.message}`
    );
  }
};

export const getUsageData = async () => {
  try{
    const response = await api.get("/playstore/fetch-redis-datas");
    // console.log("response redis data :: ", response.data);
    return  response.data
  } catch (error){
    throw new Error(
      `Error occurred while fetching usage details: ${error.message}`
    );
  }
}

export const fetchAllReviews = async (
  page,
  limit,
  source = null,
  rating = null,
  category = null,
  dateRange = null,
  dashboard_id
) => {
  try {
    let params = {
      dashboard_id,
      page,
      limit,
      ...(dateRange && {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }),
    };
    if (source) params["source"] = source;
    if (rating) params["rating"] = rating;
    if (category) params["category"] = category;
    const response = await api.get("/allReviews/all-reviews", {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching all reviews: ${error.message}`
    );
  }
};

export const fetchThemeAnomaly = async (themeID) => {
  try {
    const response = await api.get("/actionItems/get-theme-anomaly", {
      params: { theme_id: themeID },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching theme: ${error.message}`);
  }
};

export const fetchActionDevItems = async (themeID) => {
  try {
    const response = await api.get("/actionItems/analyze-action-items", {
      params: { theme_id: themeID },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching theme: ${error.message}`);
  }
};

export const fetchDataSources = async (dashboard_id) => {
  try {
    const response = await api.get(`/dataSourcesDetails/datasources/?dashboard_id=${dashboard_id}`);
    // console.log("API response : ", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching data:",
      error.response ? error.response.data : error.message
    );
    return [];
  }
};

export const fetchPlayStoreREviews = async (appId, type, dashboard_id, orgId, userId) => {
  try {
    const response = await api.post("/playstore/fetch-reviews", {
      app_id: appId,
      type: type,
      dashboard_id: dashboard_id,
      org_id: orgId,
      user_id: userId
    });
    return response;
    // return await response.json();
  } catch (error) {
    throw new Error(`Error occurred while fetching reviews: ${error.message}`);
  }
};

export const analyzeReviews = async (dashboard_id, org_id, userId) => {
  try {
    const response = await api.post(`/analysis/analyze-data?dashboard_id=${dashboard_id}&org_id=${org_id}&user_id=${userId}`);
    return response;
  } catch (error) {
    throw new Error(`Failed to analyze and save data: ${error.message}`);
  }
};

export const fetchCsvReviews = async (formData) => {
  try {
    const response = await api.post("/upload/uploadcsv", formData);
    return response.data; 
  } catch (error) {
    throw new Error(`Error occurred while fetching reviews: ${error.message}`);
  }
};

export const saveCsvReviews = async (mappedFields, dashboard_id, orgId) => {
  try {
    const response = await api.post(
      `/upload/savemappedfields?org_id=${orgId}&dashboard_id=${dashboard_id}`,
      mappedFields
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while saving reviews: ${error.message}`);
  }
};

export const validatePlayStoreId = async (appId) => {
  try {
    const response = await api.get(`/validate/validate_play_store_id/${appId}`);
    return response.data.valid;
  } catch (error) {
    throw new Error(`Error occurred while validating ID: ${error.message}`);
  }
};

// export const validatePlayStoreId = async (appId) => {
//   try {
//     const url = `https://play.google.com/store/apps/details?id=${appId}`;
//     const response = await fetch(url, { method: 'GET', mode: 'no-cors' });
//     if (response.ok) {
//       return true
//     } else {
//       return false
//     }
//   } catch (error) {
//     throw new Error(`Error occurred while validating ID: ${error.message}`);
//   }
// };

export const createFeedback = async (feedback, dashboard_id, org_id) => {
  try {
    const response = await api.post(`/feedback/add?dashboard_id=${dashboard_id}`, {
      user_name: feedback.userName,
      rating: feedback.rating,
      content: feedback.content,
      org_id: org_id
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while creating feedback: ${error.message}`);
  }
};

export const checkDashboardName = async (dashboard_name, org_id) => {
  try {
    const response = await api.post(`/dashboard/check-dashboard-name/${dashboard_name}`, {}, { params: { orgId: org_id } })
    // console.log("response: ", response);
    return response.data.exists;
  } catch (error) {
    throw new Error(
      `Error occurred while checking dashboard name: ${error.message}`
    );
  }
};

export const getDashboardIdByName = async (dashboard_name, org_id, userId) => {
  try {
    const response = await api.get(`/dashboard/get-dashboard-id/${dashboard_name}`, { params: { orgId: org_id, user_id : userId } })
    // console.log(response);
    return response.data.id;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching dashboard id: ${error.message}`
    );
  }
};

export const getDashboardStatus = async (dashboard_id, userId) => {
  try {
    await api.get(`/dashboard/send-dashboard-status/${dashboard_id}`, { params: {  user_id: userId } })
  } catch (error) {
    throw new Error(
      `Error occurred while fetching dashboard id: ${error.message}`
    );
  }
};

export const createrules = async (title, rules, dashboard_id) => {
  try {
    let url = "/rules/sample_output";
    const response = await api.post(url, { title, rules, dashboard_id });
    return response.data;
  } catch (error) {
    throw new Error(` 
      Error occurred while feteching rules_prompt : ${error.message}
      `);
  }
};

export const sample_output = async (title, rules, prompt, dashboard_id) => {
  try {
    const response = await api.post("/rules/sample_output", {
      title,
      rules,
      prompt,
      dashboard_id
    });
    // console.log("sample_output is calling :", response);
    return response.data;
  } catch (error) {
    throw new Error(` 
      Error occurred while feteching rules_prompt : ${error.message}`);
  }
};

export const rules_accept = async ({ requestData }) => {
  try {
    const response = await api.post("/rules/accept", requestData)
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during accept/submitting the rule : ${error.message}`
    )
  }
};

export const updateRule = async (id, requestData ) => {
  try {
    const response = await api.post(`/rules/update?rule_id=${id}`, requestData)
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during accept/submitting the rule : ${error.message}`
    )
  }
};

export const getRuleByTempID = async (dashboard_id, tempId) => {
  try {
    const response = await api.get(`/rules/get-custom-rule?dashboard_id=${dashboard_id}&temp_id=${tempId}`)
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during accept/submitting the rule : ${error.message}`
    )
  }
}

export const custom_rules_bookmark = async (dashboard_id) => {
  try {
    const response = await api.get(`/rules/custombookmarkrules?dashboard_id=${dashboard_id}`);
    // console.log("customerulesapi is calling")
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during feteching bookmark rules  : ${error.message}`
    )
  }
}

export const al_user_rules = async (dashboard_id) => {
  try {
    const response = await api.get(`/rules/userrules?dashboard_id=${dashboard_id}`);
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during feteching bookmark rules  : ${error.message}`
    )
  }
}

export const remove_user_rules = async (rule_id)=>{ 
  try {
    const response = await api.post("/rules/deleterule", { rule_id: rule_id });
   return response.data;
  } catch (error) {
   throw new Error ( 
     `Error occured during feteching bookmark rules  : ${error.message}`
    )
  }
}

export const getRuleById = async (id, dashboard_id) => { 
  try {
    const response = await api.get(`/rules/edit?rule_id=${id}&dashboard_id=${dashboard_id}`);
    return response.data;

  } catch (error) {
    // Handle errors and throw a custom error message
    throw new Error(`Error occurred during fetching rule by ID: ${error.message}`);
  }
};


export const fetchAPISourceToken = async (dashboard_id) => {
  try {
    const response = await api.get('/dataSourcesDetails/datasource-api-token', { params: { dashboard_id } });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching data sourec api token ${error}`)
  }
}

export const refreshAPISourceToken = async (dashboard_id) => {
  try {
    const response = await api.post('/dataSourcesDetails/datasource-api-token-refresh', { dashboard_id })
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching refreshed data source api token ${error}`)
  }
}



export const fetchDashboardData = async (orgId, dashboard_id, duration, source, review_category_type, filterNpxChart, filterSource) => {
  try {
    const response = await api.get(`/dashboard/get-dashboard-data`, {
      params: {
        org_id: orgId,
        dashboard_id: dashboard_id,
        duration: duration,
        source: source,
        review_category_type: review_category_type,
        nps_filter: filterNpxChart,
        source_filter: filterSource
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching dashboard data: ${error}`);
  }
}


export const updateDashboard = async (user_id, dashboard_id, org_id, data) => {
  try {
    // console.log(user_id);
    const payload = {
      dashboard_name: data.workspaceName,
      description: data.description,
      org_id: org_id,
      feature: data.features,
      app_type: data.appType !== 'Other' ? data.appType : data.otherAppType,
      user_id: user_id,
      update_check: true,
      feature_created: false
    };

    // console.log(payload);

    const response = await api.put(`dashboard/update-dashboard/${dashboard_id}`, payload);
    return response.data;
  } catch (error) {
    // Handle specific error response from the backend
    if (error.response && error.response.data && error.response.data.detail) {
      throw new Error(`Error: ${error.response.data.detail}`);
    }
    // Generic error handling
    throw new Error(`Error occurred while updating dashboard: ${error.message}`);
  }
};
