import { useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Chart } from 'react-google-charts';
import { useFeedbackQContext } from '../../context/FeedbackQ';

export default function NPXChart({ npsData }) {
  const { filterNpxChart, setFilterNpxChart } = useFeedbackQContext();

  const handleFilterChange = (event) => {
    setFilterNpxChart(event.target.value);
  };

  // Determine the x-axis title based on the filter selection
  const getXAxisTitle = () => {
    switch (filterNpxChart) {
      case 'Weekly':
        return 'Week';
      case 'Monthly':
        return 'Month';
      case 'Yearly':
        return 'Year';
      default:
        return 'Day'; // Default is daily
    }
  };

  const options = {
    title: 'NPS Score and No. of Responses',
    titleTextStyle: { color: '#757575', fontSize: 16 },
    seriesType: 'bars',
    series: {
      0: { type: 'line', color: '#8e24aa', targetAxisIndex: 0 },
      1: { color: '#e1bee7', targetAxisIndex: 1 },
    },
    legend: { position: 'top', alignment: 'end' },
    chartArea: { width: '80%', height: '70%' },
    vAxes: {
      0: { title: 'NPS Score', viewWindow: { min: -100, max: 100 } },
      1: { title: 'No. of Responses', viewWindow: { min: 0, max: 100 } },
    },
    hAxis: {
      title: getXAxisTitle(),
      slantedText: true,
      slantedTextAngle: 40, // Increase the slant to fit more date text
      textStyle: {
        fontSize: 12,
      },
    },
    backgroundColor: { fill: 'transparent' },
  };

  return (
    <Box sx={{ p: 2, bgcolor: 'background.paper', position: 'relative' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" component="div">
          Net Promoter Score (NPS)
        </Typography>

        {/* Dropdown next to the title */}
        <FormControl
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            boxShadow: 1,
            borderRadius: 1,
            minWidth: 100,
            ml: 2,
          }}
          size="small"
        >
          <InputLabel id="filter-label">Filter</InputLabel>
          <Select
            labelId="filter-label"
            value={filterNpxChart}
            label="Filter"
            onChange={handleFilterChange}
          >
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Yearly">Yearly</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Chart
        chartType="ComboChart"
        width="100%"
        height="400px"
        data={npsData}
        options={options}
      />
    </Box>
  );
}
