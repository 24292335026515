import { Card, CardContent, Typography, Box, useMediaQuery } from "@mui/material";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

const NetPromoterScore = ({ score }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <Card sx={{ borderRadius: 2, padding: { xs: 1, sm: 2 }, minWidth: 200 }}>
      <CardContent>
        <Typography variant={isSmallScreen ? "h6" : "h5"} className="font-bold text-gray-700 text-center">
          Net Promoter Score
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Gauge
            width={isSmallScreen ? 180 : 250}
            height={isSmallScreen ? 180 : 250}
            value={score}
            startAngle={-90}  
            endAngle={180}     
            sx={{
              [`&.${gaugeClasses.valueText}`]: {
                fontSize: isSmallScreen ? 40 : 60,
                transform: 'translate(0px, 0px)',
                textShadow: 2
              }
            }}
            text={
              ({ value, valueMax }) => `${value}/ ${valueMax}`
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NetPromoterScore;

