import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import TopFiveTheme from "./TopFiveThemes";
import MonthlyVolumeChart from "./MonthlyVolumeChart";

const ChartCarousel = ({ sentimentData, themeAnalysis }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleCarouselChange = (index) => {
        setActiveIndex(index); // Update active index
    };

    return (
        <Carousel
            autoPlay
            interval={5000}
            animation="fade"
            navButtonsAlwaysVisible={false}
            indicators
            onChange={handleCarouselChange}
        >
            <div style={{ height: '100%', width: '100%' }}>
                <TopFiveTheme themeAnalysis={themeAnalysis} />
            </div>
            <div style={{ height: '100%', width: '100%' }}>
                {/* Pass a unique key to force re-render */}
                <MonthlyVolumeChart key={activeIndex === 1 ? "active" : "inactive"} sentimentData={sentimentData} />
            </div>
        </Carousel>
    );
};

export default ChartCarousel;
