import React, { useState } from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import './App.css'
import SideBar from './components/Layouts/Sidebar';
import CreateOrCollectFeedback from './components/CreateCollectFeedback';
import TitleSection from './components/TitleSection';
import AppFormData from './components/AppFormData';
import Header from './components/Layouts/Header';
import {
  createBrowserRouter,
  Outlet
} from "react-router-dom";
import Snippet from "./components/Snippets"
import FeatureList from './components/SecondThemeView';
import Themes from './components/Themes';
import { FeedbackQProvider } from './context/FeedbackQ';
import { theme } from './muitheme/theme';
import { useEffect } from "react"
import Rules from './components/Rules'
import AskQ from './components/AskQ';
import CreateRule from './components/rules/CreateRule';
import CustomError from './components/utils/CustomError';
import { fetchDashboards, getOrgData } from './api/API';
import "./App.css";
import { NotificationProvider } from "./context/Notification";
import Footer from './components/Layouts/Footer';
import Dashboard from './components/Dashboard';
import BookYourCall from './components/BookCall';
import UserManagement from './components/UserMangement';
import { connectWebSocket } from './helper/websocket';
import { LoaderProvider } from './context/LoaderContext';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(()=>{
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = e =>{
      if(e.matches){
        setIsMobile(true);
        setSidebarOpen(false);
      }else{
        setIsMobile(false);
        setSidebarOpen(true);
      }
    }
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return ()=>{
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  useEffect(() => {
    const getSessionData = async () => {
      await fetchSessionData();
    };
    if (
      process.env.REACT_APP_ENVIRONMENT === "production" ||
      process.env.REACT_APP_DJANGO_RUN === '1'
    ) {
      getSessionData();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchSessionData = async () => {
    let BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      BASE_URL = "https://insightq.ai";
    }
    try {
      const response = await fetch(`${BASE_URL}/api/get-session-data/`, {
        credentials: "include", // This is important to include cookies in the request
      });
      const data = await response.json();
      const user = JSON.parse(data.user);

      if (!data.auth_token) {
        window.location.href = `${BASE_URL}/login/`;
      } else {
        sessionStorage.setItem("auth_token", data.auth_token);
        sessionStorage.setItem("orgId", data.organization_id);
        sessionStorage.setItem("userId", data.user_id);
        sessionStorage.setItem("expiryDate", data.expiry_date);
        sessionStorage.setItem("is_org_admin", user.is_org_admin);
        
        if (!localStorage.getItem('dashboardId')){
          const dashboards = await fetchDashboards(data.organization_id);
          if(dashboards){
            // console.log("dashboards :: ", dashboards)
            localStorage.setItem('dashboardId', dashboards[0]._id)
          }
        }
        
        setLoading(false);
        const tokenResponse = await getOrgData(data.organization_id);

        // connectWebSocket(data.user_id)
        // if(tokenResponse.days_left )
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (loading ) {
    return process.env.REACT_APP_DJANGO_RUN === "1" ? (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h1">Run Django Server</Typography>
      </Box>
    ) : null;
  }

  return (
    <ThemeProvider theme={theme}>
      <FeedbackQProvider>
        <NotificationProvider>
          <LoaderProvider >
          <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }} >
            <Box sx={{ display: "flex", flex: 1 }}>
              { sidebarOpen && <SideBar /> }
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Header toggleSidebar={toggleSidebar} isMobile={isMobile} />
                <Box sx={{ flex: 1, paddingTop: "75px" }}>
                  <Outlet />
                </Box>
                <Footer />
              </Box>
            </Box>
          </Box>
            </LoaderProvider>
        </NotificationProvider>
      </FeedbackQProvider>
    </ThemeProvider>
  );

};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <CustomError />,
    children: [
      {
        path: "/",
        element: <TitleSection />,
        errorElement: <CustomError />,
      },
      {
        path: "/source",
        element: <CreateOrCollectFeedback />,
        errorElement: <CustomError />,
      },
      {
        path: "/source/appstore",
        element: (
          <AppFormData
            sourceName="App Store"
            sourceDescription="Add a list of App Store apps to monitor for reviews. New reviews are fetched daily. You can also come here to trigger the fetch manually."
            example={"AppStore"}
          />
        ),
        errorElement: <CustomError />,
      },
      {
        path: "/source/playstore",
        element: (
          <AppFormData
            sourceName="Google Play Store"
            sourceDescription="Connect to your app on Google Play Store."
            example={"PlayStore"}
          />
        ),
        errorElement: <CustomError />,
      },
      {
        path: "/reviews",
        element: <Snippet />,
        errorElement: <CustomError />,
      },
      {
        path: "/themes",
        element: <Themes />,
        errorElement: <CustomError />,
      },
      {
        path: "/themes1",
        element: <FeatureList />,
        errorElement: <CustomError />,
      },
      {
        path: "/rules",
        element: <Rules />,
        errorElement: <CustomError />,
      },
      {
        path: "/rules/create-new",
        element: <CreateRule />,
        errorElement: <CustomError />,
      },
      {
        path: "/rules/edit/:id",
        element: <CreateRule />,
        errorElement: <CustomError />,
      },
      {
        path: "/ask-q",
        element: <AskQ />,
        errorElement: <CustomError />,
      },
      {
        path: "/book-call",
        element: <BookYourCall />,
        errorElement: <CustomError />,
      },
      {
        path: "/manage-user",
        element: <UserManagement />,
        errorElement: <CustomError />,
      },
    ],
  },
  {
    path: '*',
    element: <CustomError />,
  }
]);


export default appRouter;
