import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useFeedbackQContext } from '../context/FeedbackQ';
import ThemeDrawer from './theme/secondview/ThemeDrawer';
import ListHolder from './theme/ListHolder';

const FeatureList = ({ handleToggleView, view }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [themesData, setThemesData] = useState([]);
    // const [themeAnalysisData, setThemeAnalysisData] = useState({});
    const [selectionRange, setSelectionRange] = useState({
        startDate: null,
        endDate: null,
        key: 'selection'
    });
    const [showCalendar, setShowCalendar] = useState(false);

    const {
        themes,
        loadingThemes,
        themeError,
        selectedItem,
        setSelectedItem
    } = useFeedbackQContext();

    useEffect(() => {
        if(!loadingThemes && !themeError){
            setThemesData(themes);
        }
    }, [themes,loadingThemes,themeError]);

    // useEffect(() => {
    //     if (!loadingAnalysis && !analysisError) {
    //         setThemeAnalysisData(themeAnalysis);
    //     }
    // }, [themeAnalysis, loadingAnalysis, analysisError]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const handleDateRangeChange = (ranges) => {
        setSelectionRange(ranges.selection);
    };

    const handleOpenDrawer = (item) => {
        setSelectedItem(item);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedItem(null);
    };

    const filteredData = themesData.filter(item => item?.type !== 'Spams');
    
    const totalCount = filteredData.length;
    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box className="w-full max-w-[90rem] h-full text-black mx-auto p-0 sm:p-0 md:p-0 box-border mb-2 md:mb-6"  >
                <ListHolder 
                    handleToggleView={handleToggleView}
                    view={view}
                    paginatedData={paginatedData}
                    handleOpenDrawer={handleOpenDrawer}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    totalCount={totalCount}
                />
                { selectedItem && (
                    <ThemeDrawer 
                        drawerOpen={drawerOpen}  
                        handleCloseDrawer={handleCloseDrawer} 
                        themeData={selectedItem} 
                    />
                )}
            
        </Box>
    );
};

export default FeatureList;
