import React from 'react';
import { LinearProgress, Box, Typography, Paper } from '@mui/material';

const ReviewsLoader = ({ message }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        width: '95%', // Balanced width
        maxWidth: '800px',
        backgroundColor: '#f4f6f8',
        padding: '12px 16px', // Reduced padding for a compact look
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        position: 'absolute',
        bottom: '110px',
        zIndex: 1000,
        left: '50%',
        transform: 'translateX(-50%)',
        border: '1px solid #d1d9e6', // Subtle border for clarity
      }}
    >
      <Typography
        variant="body2" // Smaller font size
        sx={{
          color: '#2d3e50', // Professional dark color
          fontWeight: 430,
          textAlign: 'center',
          marginBottom: '8px',
        }}
      >
        {message}
      </Typography>
      <LinearProgress
        color="primary"
        sx={{
          height: 6, // Slightly thicker loader for visibility
          borderRadius: '4px',
        }}
      />
    </Paper>
  );
};

export default ReviewsLoader;
