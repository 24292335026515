let socket;

export function connectWebSocket(userId, showLoader, hideLoader) {
    if (socket) return; 
    // console.log("Environment URL:", process.env.REACT_APP_DJANGO_WS_URL);
    // console.log("User ID:", userId);

    try {
        let url = `ws://${process.env.REACT_APP_DJANGO_WS_URL}/ws/${userId}`;
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            url = `wss://${process.env.REACT_APP_DJANGO_WS_URL}/ws/${userId}`;
        }
        // console.log("WebSocket URL:", url);
        socket = new WebSocket(url);
    } catch (error) {
        console.error("WebSocket initialization error:", error);
    }

    socket.onopen = () => {
        // console.log("WebSocket connected");
    };

    socket.onmessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            // console.log("WebSocket Message:", data);

            switch (data.data) {
                case "progress":
                    showLoader("Fetching feedbacks for dashboard");
                    break;

                case "exhaust":
                case "token_exhausted":
                case "completed":
                case "stop":
                    hideLoader();
                    break;
                    
                case "feature_created":
                    hideLoader();
                    break;

                case "error":
                    hideLoader();
                    console.error(`Error: ${data.message || "Unknown error occurred"}`);
                    break;

                case "analyzing":
                    showLoader("Analyzing feedbacks for nps, themes and empathies");
                    break;

                case "nps_completed":
                    showLoader("NPS analysis completed......\nAnalyzing feedbacks for themes and empathies");
                    break;

                case "feature_creating":
                    showLoader("Dashboard is creating......");
                    break;

                default:
                    console.warn(`Unhandled WebSocket message type: ${data.data}`);
            }
        } catch (err) {
            console.error("Failed to process WebSocket message:", err);
        }
    };


    socket.onclose = () => {
        hideLoader();
        // console.log("WebSocket closed");
    };

    socket.onerror = (error) => {
        hideLoader();
        // console.error("WebSocket error:", error);
    };
}


export function disconnectWebSocket() {
    if (socket) {
        socket.close();
        // console.log("WebSocket connection closed");
        socket = null;
    }
}

