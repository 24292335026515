import { Box, Grid } from "@mui/material";
import { dashboardCardData } from "./dummyData";
import CardComponent from "./Dashboard/CardComponent";
import CardComponentWithImage from "./Dashboard/CardComponentWithImage";
import HeaderSection from "./Dashboard/HeaderSection";
import useCardGrid, { useCardGrid2 } from "../hooks/useCardGrid";
import CustomerSatisfactionScore from "./Dashboard/CustomerSatisfactionScore";
import NetPromoterScore from "./Dashboard/NetPromoterScore";
import ProgressBlock from "./Dashboard/ProgressBlock";
import NPXChart from "./Dashboard/NPXChart";
import NPXTradeComparison from "./Dashboard/NPXTradeComparison";
import { WordCloudCard } from "./utils/WordCloudCard";
import EmpathyCharts from "./charts/EmpathyCharts";
import { useEffect, useMemo, useState } from "react";
import SkeletonDashboard from "./utils/shimmerUI/SkeletonDashboard";

const Dashboard = ({data, themeCheck}) => {
    const [categoryData, setCategoryData] = useState({});
    const [sourceData, setSourceData] = useState({});
    const [csat, setCsat] = useState(0);
    const [nps, setNps] = useState(0);
    const [isCategoryLoading, setIsCategoryLoading] = useState(true);
    const [isSourceLoading, setIsSourceLoading] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [npsDistribution, setNpsDistribution] = useState({
        Promoters: { count: 0, percentage: 0 },
        Detractors: { count: 0, percentage: 0 },
        Passives: { count: 0, percentage: 0 }
    });
    const [npsData, setNpsData] = useState([['Month', 'NPS Score', 'No. of Responses'], ['month', 0, 0]]);
    const [monthlyNPSData, setMonthlyNPSData] = useState({
        csv: Array(12).fill(0),         // Initialize with 0 for each month
        api: Array(12).fill(0),
        play_store: Array(12).fill(0),
        app_store: Array(12).fill(0),
        g2: Array(12).fill(0),
    });
    const [months, setMonths] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);
    const [promotersWordCloud, setPromotersWordCloud] = useState([
        { text: 'initialWord1', value: 50 },
        { text: 'initialWord2', value: 30 },
        { text: 'initialWord3', value: 75 },
        { text: 'initialWord4', value: 45 },
        { text: 'initialWord5', value: 90 }
    ]);
    const [detractorsWordCloud, setDetractorsWordCloud] = useState([
        { text: 'initialWord1', value: 50 },
        { text: 'initialWord2', value: 30 },
        { text: 'initialWord3', value: 75 },
        { text: 'initialWord4', value: 45 },
        { text: 'initialWord5', value: 90 }
    ]);
    const [empathyState, setEmpathyState] = useState({
        Says: {
            title: 'SAYS',
            question: 'What do you hear them saying? What do they talk about?',
            data: []
        },
        Thinks: {
            title: 'THINKS',
            question: 'What are they worried/excited about? What are they thinking about?',
            data: []
        },
        Does: {
            title: 'DOES',
            question: 'What are their daily activities (at home or at work)?',
            data: []
        },
        Feels: {
            title: 'FEELS',
            question: 'How do they feel on a daily basis?',
            data: []
        },
        Pain: {
            title: 'PAIN',
            question: 'What are their frustrations and challenges?',
            data: []
        },
        Gain: {
            title: 'GAIN',
            question: 'What are their goals? What makes them happy?',
            data: []
        }
    });
    const [persona, setPersona] = useState({
        Role: 'Name/Role/Persona',
        Tech: 'savvy Project Manager',
        Age: '35 - 45',
        Experience: '10 + years'
    })

    const isDataComplete = (data) => (
        data?.csat !== undefined &&
        data?.nps !== undefined &&
        data?.nps_distribution !== undefined &&
        data?.source_count_data !== undefined &&
        data?.category_count_data !== undefined &&
        data?.nps_by_source !== undefined &&
        data?.promoters_word_cloud !== undefined &&
        data?.detractor_word_cloud !== undefined
    );

    useEffect(() => {
        if (data && Object.keys(data).length > 0 && isDataComplete(data)) {
            if (isDataComplete) {
                setCategoryData({
                    newFeatureRequest: {
                        count: data?.category_count_data?.['New Feature Request']?.count,
                        percentage: data?.category_count_data?.['New Feature Request']?.percentage,
                    },
                    bugReport: {
                        count: data?.category_count_data?.['Bug Report']?.count,
                        percentage: data?.category_count_data?.['Bug Report']?.percentage,
                    },
                    appreciation: {
                        count: data?.category_count_data?.Appreciation?.count,
                        percentage: data?.category_count_data?.Appreciation?.percentage,
                    },
                    question: {
                        count: data?.category_count_data?.Question?.count,
                        percentage: data?.category_count_data?.Question?.percentage,
                    },
                    spam: {
                        count: data?.category_count_data?.Spam?.count,
                        percentage: data?.category_count_data?.Spam?.percentage,
                    },
                    totalReviews: {
                        count: data?.category_count_data?.['Total Reviews']?.count,
                        percentage: data?.category_count_data?.['Total Reviews']?.percentage,
                    },
                    categorizedReviews: {
                        count: data?.category_count_data?.['Categorized Reviews']?.count,
                        percentage: data?.category_count_data?.['Categorized Reviews']?.percentage,
                    },
                });

                setSourceData({
                    playStore: {
                        count: data?.source_count_data?.play_store?.count,
                        percentage: data?.source_count_data?.play_store?.percentage,
                    },
                    appStore: {
                        count: data?.source_count_data?.app_store?.count,
                        percentage: data?.source_count_data?.app_store?.percentage,
                    },
                    csv: {
                        count: data?.source_count_data?.csv?.count,
                        percentage: data?.source_count_data?.csv?.percentage,
                    },
                    api: {
                        count: data?.source_count_data?.api?.count,
                        percentage: data?.source_count_data?.api?.percentage,
                    },
                    g2: {
                        count: data?.source_count_data?.g2?.count,
                        percentage: data?.source_count_data?.g2?.percentage,
                    },
                });

                setCsat(data.csat);
                setNps(data.nps);

                setNpsDistribution({
                    Promoters: {
                        count: data?.nps_distribution?.Promoters?.count,
                        percentage: data?.nps_distribution?.Promoters?.percentage,
                    },
                    Detractors: {
                        count: data?.nps_distribution?.Detractors?.count,
                        percentage: data?.nps_distribution?.Detractors?.percentage,
                    },
                    Passives: {
                        count: data?.nps_distribution?.Passives?.count,
                        percentage: data?.nps_distribution?.Passives?.percentage,
                    },
                });

                setNpsData(data?.monthly_nps);
                setMonths(data?.nps_by_source?.sorted_months);
                setMonthlyNPSData(data?.nps_by_source?.source_results);
                setPromotersWordCloud(data?.promoters_word_cloud || []);
                setDetractorsWordCloud(data?.detractor_word_cloud || []);

                const extractedEmpathies = data?.empathies;

                setEmpathyState({
                    Says: {
                        title: 'SAYS',
                        question: 'What do you hear them saying? What do they talk about?',
                        data: extractedEmpathies?.Says?.data || []
                    },
                    Thinks: {
                        title: 'THINKS',
                        question: 'What are they worried/excited about? What are they thinking about?',
                        data: extractedEmpathies?.Thinks?.data || []
                    },
                    Does: {
                        title: 'DOES',
                        question: 'What are their daily activities (at home or at work)?',
                        data: extractedEmpathies?.Does?.data || []
                    },
                    Feels: {
                        title: 'FEELS',
                        question: 'How do they feel on a daily basis?',
                        data: extractedEmpathies?.Feels?.data || []
                    },
                    Pain: {
                        title: 'PAIN',
                        question: 'What are their frustrations and challenges?',
                        data: extractedEmpathies?.Pain?.data || []
                    },
                    Gain: {
                        title: 'GAIN',
                        question: 'What are their goals? What makes them happy?',
                        data: extractedEmpathies?.Gain?.data || []
                    }
                });
            }
        }
    }, [data]);
    

    const [imageDashboardCardData, setImageDashboardCardData] = useState([{ title: "CSV", value: "0", percentage: 0 },
    { title: "App Store", value: "0", percentage: 0 },
    { title: "Play Store", value: "0", percentage: 0 },
    { title: "API", value: "0", percentage: 0 },
    { title: "G2", value: "0", percentage: 0 }])
    
    const [dashboardCardData, setDashboardCardData] = useState(
        [{ title: "Total Feedbacks", value: 0, percentage: 0 },
        { title: "New Feature", value: 0, percentage: 0 },
        { title: "Bug Reports", value: 0, percentage: 0 },
        { title: "Questions", value: 0, percentage: 0 },
        { title: "Appreciations", value: 0, percentage: 0 },
        { title: "Spams", value: 0, percentage: 0 },
        { title: "Categorized Feedbacks", value: 0, percentage: 0 }])

        useEffect(() => {
            if (!categoryData) {
                setIsCategoryLoading(true);
                return;
            }

            const newDashboardCardData = [
                { title: "Total Feedbacks", value: categoryData?.totalReviews?.count, percentage: categoryData?.totalReviews?.percentage },
                { title: "New Feature", value: categoryData?.newFeatureRequest?.count, percentage: categoryData?.newFeatureRequest?.percentage },
                { title: "Bug Reports", value: categoryData?.bugReport?.count, percentage: categoryData?.bugReport?.percentage },
                { title: "Questions", value: categoryData?.question?.count, percentage: categoryData?.question?.percentage },
                { title: "Appreciations", value: categoryData?.appreciation?.count, percentage: categoryData?.appreciation?.percentage },
                { title: "Spams", value: categoryData?.spam?.count, percentage: categoryData?.spam?.percentage },
                { title: "Categorized Feedbacks", value: categoryData?.categorizedReviews?.count, percentage: categoryData?.categorizedReviews?.percentage }
            ];
            
            setDashboardCardData(newDashboardCardData);
            setIsCategoryLoading(false); // Loading is false once data updates
        }, [categoryData]);
    
    // const memoizedDashboardCardData = useMemo(() => {
    //     if (categoryData) {
    //         return [
    //             { title: "Total Feedbacks", value: categoryData?.totalReviews?.count, percentage: categoryData?.totalReviews?.percentage },
    //             { title: "New Feature", value: categoryData?.newFeatureRequest?.count, percentage: categoryData?.newFeatureRequest?.percentage },
    //             { title: "Bug Reports", value: categoryData?.bugReport?.count, percentage: categoryData?.bugReport?.percentage },
    //             { title: "Questions", value: categoryData?.question?.count, percentage: categoryData?.question?.percentage },
    //             { title: "Appreciations", value: categoryData?.appreciation?.count, percentage: categoryData?.appreciation?.percentage },
    //             { title: "Spams", value: categoryData?.spam?.count, percentage: categoryData?.spam?.percentage },
    //         ];
    //     }
    //     return [];
    // }, [categoryData]);

        useEffect(() => {
            if (!sourceData) {
                setIsSourceLoading(true);
                return;
            }

            const newImageDashboardCardData = [
                { title: "CSV", value: sourceData?.csv?.count, percentage: sourceData?.csv?.percentage },
                    { title: "App Store", value: sourceData?.appStore?.count, percentage: sourceData?.appStore?.percentage },
                    { title: "Play Store", value: sourceData?.playStore?.count, percentage: sourceData?.playStore?.percentage },
                    { title: "API", value: sourceData?.api?.count, percentage: sourceData?.api?.percentage },
                    { title: "G2", value: sourceData?.g2?.count, percentage: sourceData?.g2?.percentage },
                ];

                setImageDashboardCardData(newImageDashboardCardData);
                setIsSourceLoading(false); // Loading is false once data updates
        }, [sourceData]);
        const firstCardRow = useCardGrid(dashboardCardData, CardComponent, themeCheck);
        // const firstCardRow = useCardGrid(memoizedDashboardCardData, CardComponent);
        const secondCardRow = useCardGrid2(imageDashboardCardData, CardComponentWithImage);

        useEffect(() => {
            if (!isCategoryLoading && !isSourceLoading) {
                setIsDataLoading(false);
            }
        }, [isCategoryLoading, isSourceLoading]);

    if (isDataLoading) {
            return <SkeletonDashboard />;
        }

        return (
        <>
            <Box
                className="bg-white min-h-screen"
                sx={{
                    padding: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                <Box className='container mx-auto px-4 sm:px-6 md:px-8'>
                    <HeaderSection themeCheck={themeCheck}/>
                    {firstCardRow}
                    {secondCardRow}
                    <Grid container spacing={4} marginY={3}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <CustomerSatisfactionScore value={csat} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <NetPromoterScore score={nps} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <ProgressBlock 
                                detractors={npsDistribution?.Detractors}
                                passives={npsDistribution?.Passives}
                                promoters={npsDistribution?.Promoters}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NPXChart npsData={npsData} />
                        </Grid>
                        <Grid item xs={12}>
                            <NPXTradeComparison months={months} monthlyNPSData={monthlyNPSData} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WordCloudCard title="Promoters" words={promotersWordCloud} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WordCloudCard title="Detractors" words={detractorsWordCloud} />
                        </Grid>
                        <Grid item xs={12} className="mt-2">
                            <EmpathyCharts empathyState={empathyState} persona={persona} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Dashboard;





