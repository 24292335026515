/* global google */
import React, { useEffect } from "react";

const ThemeListChart = ({ data, chartId }) => {
    useEffect(() => {
        const loadGoogleCharts = () => {
            google.charts.load("current", { packages: ["corechart"] });
            google.charts.setOnLoadCallback(drawChart);
        };

        const drawChart = () => {
            const chartData = google.visualization.arrayToDataTable(data);

            const options = {
                legend: { position: "none" },
                hAxis: {
                    title: "Trend (30d)",
                    textStyle: { fontSize: 12 },
                },
                // vAxis: {
                //     title: "Count",
                //     textStyle: { fontSize: 12 },
                // },
                bar: { groupWidth: "80%" },
                colors: ["#4285F4"],
            };

            const chart = new google.visualization.ColumnChart(
                document.getElementById(chartId)
            );
            chart.draw(chartData, options);
        };

        if (!window.google) {
            const script = document.createElement("script");
            script.src = "https://www.gstatic.com/charts/loader.js";
            script.onload = loadGoogleCharts;
            document.body.appendChild(script);
        } else {
            loadGoogleCharts();
        }
    }, [data, chartId]);

    return <div id={chartId} style={{ width: "500px", height: "80px" }} />;
};

export default ThemeListChart;

