import React from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
} from '@mui/material';
import { useFeedbackQContext } from '../../context/FeedbackQ';

// Define the durations, sources, and categories outside the component
const durations = [
    'Overall', 'Today', 'Yesterday', '2 days ago', '3 days ago', 'Last 7 days',
    'Last 30 days', '60 days', '90 days', '180 days'
];

const sources = ['Overall','CSV', 'API', 'Google'];

const categories = ['Overall', 'New Features', 'Bugs', 'Appreciation', 'Questions', 'Spam'];

const DashboardFilter = () => {
    const { duration, setDuration, source, setSource, category, setCategory } = useFeedbackQContext();

    return (
        <Box sx={{ maxWidth: 1400, padding: 0 }}>
            {/* Flexbox container for filters */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
                {/* Duration dropdown */}
                <FormControl sx={{ minWidth: 200, mr: 1 }} size="small">
                    <InputLabel id="duration-label">Duration</InputLabel>
                    <Select
                        labelId="duration-label"
                        value={duration}
                        label="Duration"
                        onChange={(e) => setDuration(e.target.value)}
                    >
                        {durations.map((d) => (
                            <MenuItem key={d} value={d}>{d}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Source and Category dropdowns */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl sx={{ minWidth: 120, mr: 1 }} size="small">
                        <InputLabel id="source-label">Source</InputLabel>
                        <Select
                            labelId="source-label"
                            value={source}
                            label="Source"
                            onChange={(e) => setSource(e.target.value)}
                        >
                            {sources.map((s) => (
                                <MenuItem key={s} value={s}>{s}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            labelId="category-label"
                            value={category}
                            label="Category"
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {categories.map((c) => (
                                <MenuItem key={c} value={c}>{c}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

export default DashboardFilter;
