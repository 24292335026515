'use client'

import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
} from '@mui/material'
import axios from 'axios'

const UserManagement = () => {
    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(false)
    const [editingUser, setEditingUser] = useState(null)
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '', status: true })
    const [maxSeats, setMaxSeats] = useState(0)
    const [error, setError] = useState('') 
    let BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        BASE_URL = "https://insightq.ai";
    }
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const orgId = sessionStorage.getItem('orgId')
                const { data } = await axios.get(`${BASE_URL}/api/list-all-org-users/?org_id=${orgId}`)
                const formattedUsers = data.users.map(user => ({
                    id: user.id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    joinedDate: new Date(user.date_joined).toISOString().split('T')[0],
                    status: user.is_active,
                }))
                setMaxSeats(data.seats.max_seats)
                setUsers(formattedUsers)
            } catch (error) {
                console.error("Error fetching users:", error)
            }
        }

        const isOrgAdmin = JSON.parse(sessionStorage.getItem('is_org_admin') || 'false')
        if (isOrgAdmin) fetchUsers()
    }, [])

    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false)
        setEditingUser(null)
        setNewUser({ firstName: '', lastName: '', email: '', status: true })
        setError('') 
    }

    const handleAddUser = async () => {
        if (!newUser.firstName || !newUser.lastName || !newUser.email) {
            setError('All fields are required to add a user.')
            return
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (!emailRegex.test(newUser.email)) {
            setError('Please enter a valid email address.')
            return
        }

        try {
            const orgId = sessionStorage.getItem('orgId')
            const payload = {
                org_id: orgId,
                first_name: newUser.firstName,
                last_name: newUser.lastName,
                email: newUser.email,
            }

            const { data } = await axios.post(`${BASE_URL}/api/add-user/`, payload)
            // console.log("User added successfully:", data)

            const addedUser = {
                id: data.user_id,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                name: `${newUser.firstName} ${newUser.lastName}`,
                email: newUser.email,
                joinedDate: new Date().toISOString().split('T')[0],
                status: true,
            }

            setUsers([...users, addedUser])
            handleClose()
        } catch (error) {
            console.error("Error adding user:", error.response?.data || error.message)
        }
    }

    const handleEditUser = async () => {
        if (editingUser) {
            try {
                const orgId = sessionStorage.getItem('orgId')
                const payload = {
                    org_id: orgId,
                    user_id: editingUser.id,
                    first_name: editingUser.firstName,
                    last_name: editingUser.lastName,
                    email: editingUser.email,
                    status: editingUser.status,
                }

                const { data } = await axios.put(`${BASE_URL}/api/update-user/`, payload)
                // console.log("User updated successfully:", data)

                const updatedUsers = users.map((user) =>
                    user.id === editingUser.id
                        ? {
                            ...editingUser,
                            name: `${editingUser.firstName} ${editingUser.lastName}`,
                            status: editingUser.status,
                        }
                        : user
                )

                setUsers(updatedUsers)
                handleClose()
            } catch (error) {
                console.error("Error updating user:", error.response?.data || error.message)
            }
        }
    }

    const handleEdit = (user) => {
        setEditingUser({
            ...user,
            firstName: user.firstName,
            lastName: user.lastName,
        })
        setOpen(true)
    }

    const activeUsersCount = users.filter(user => user.status).length
    const canAddOrActivate = activeUsersCount < maxSeats

    return (
        <div className="p-5 ms-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-3xl font-bold">Users</h1>
                <Tooltip title={!canAddOrActivate ? 'Max seats reached' : ''}>
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpen}
                            disabled={!canAddOrActivate}
                        >
                            + Add User
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Joined Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.joinedDate}</TableCell>
                                <TableCell>{user.status ? 'Active' : 'Inactive'}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(user)}>Edit</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editingUser ? 'Edit User' : 'Add New User'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={editingUser ? editingUser.firstName : newUser.firstName}
                        onChange={(e) =>
                            editingUser
                                ? setEditingUser({ ...editingUser, firstName: e.target.value })
                                : setNewUser({ ...newUser, firstName: e.target.value })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={editingUser ? editingUser.lastName : newUser.lastName}
                        onChange={(e) =>
                            editingUser
                                ? setEditingUser({ ...editingUser, lastName: e.target.value })
                                : setNewUser({ ...newUser, lastName: e.target.value })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={editingUser ? editingUser.email : newUser.email}
                        onChange={(e) =>
                            editingUser
                                ? setEditingUser({ ...editingUser, email: e.target.value })
                                : setNewUser({ ...newUser, email: e.target.value })
                        }
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    {editingUser && (
                        <div className="flex items-center mt-2">
                            <span className="mr-2">Status:</span>
                            <Tooltip title={!canAddOrActivate && !editingUser.status ? 'Max seats reached' : ''}>
                                <span>
                                    <Switch
                                        checked={editingUser.status}
                                        onChange={(event) =>
                                            setEditingUser({
                                                ...editingUser,
                                                status: event.target.checked, // Use 'event.target.checked' here
                                            })
                                        }
                                        disabled={!editingUser.status && !canAddOrActivate}
                                    />
                                </span>
                            </Tooltip>
                            <span>{editingUser.status ? 'Active' : 'Inactive'}</span>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={editingUser ? handleEditUser : handleAddUser}>
                        {editingUser ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UserManagement
