import {
    Box,
    IconButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import FeatureRequests from '../FirstThemeView';
import feature from '../../assets/icons/gear.png';
import bug from '../../assets/icons/bugs.png';
import ThemeTableSection from './secondview/ThemeTableSection';
import { useState } from 'react';

const ListHolder = ({
    handleToggleView,
    view,
    paginatedData,
    handleOpenDrawer,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount
}) => {
    const [sourceFilter, setSourceFilter] = useState('');
    const [customRange, setCustomRange] = useState({ from: '', till: '' });

    const handleDateFilter = (days) => {
        const today = new Date();
        const till = today.toISOString().split('T')[0]; // Today's date
        let from = '';

        if (days === 'all') {
            from = '';
        } else {
            const filteredDate = new Date(today);
            filteredDate.setDate(today.getDate() - days); // Subtract days
            from = filteredDate.toISOString().split('T')[0];
        }

        setCustomRange({ from, till });
    };

    const handleSourceChange = (event) => setSourceFilter(event.target.value);

    const handleCustomRangeChange = (field, value) => {
        setCustomRange((prev) => ({ ...prev, [field]: value }));
    };

    const boxStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flex: 1,
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
        padding: 3,
        borderRadius: 8,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        backgroundColor: "background.paper",
        "&:hover": {
            transform: "scale(1.05)",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
        },
    };

    return (
        <Box
            sx={{
                border: "2px solid #ddd",
                borderRadius: "12px",
                bgcolor: "background.paper",
                padding: '30px',
                width: "100%",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                display: "flex",
                flexDirection: "column",
                gap: 0,
            }}
        >
            {/* Parent Box for Filter and View Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: "wrap" }}>
                    <FormControl size="small" sx={{ minWidth: 200 }} disabled>
                        <InputLabel>Filter by Source</InputLabel>
                        <Select
                            value={sourceFilter}
                            onChange={handleSourceChange}
                            label="Filter by Source"
                            disabled
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            <MenuItem value="Source1">Source 1</MenuItem>
                            <MenuItem value="Source2">Source 2</MenuItem>
                            <MenuItem value="Source3">Source 3</MenuItem>
                        </Select>
                    </FormControl>

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: "wrap" }}>
                        {[90, 60, 30, 7].map((days) => (
                            <Button
                                key={days}
                                variant="outlined"
                                size="small"
                                onClick={() => handleDateFilter(days)}
                                sx={{ minWidth: 100 }}
                                disabled
                            >
                                {`${days} days`}
                            </Button>
                        ))}
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleDateFilter('all')}
                            sx={{ minWidth: 100 }}
                            disabled
                        >
                            All
                        </Button>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            size="small"
                            label="From"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={customRange.from}
                            onChange={(e) => handleCustomRangeChange('from', e.target.value)}
                            disabled
                        />
                        <TextField
                            size="small"
                            label="Till"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={customRange.till}
                            onChange={(e) => handleCustomRangeChange('till', e.target.value)}
                            disabled
                        />
                    </Box>
                </Box>


                {/* Toggle View Icon */}
                <Box
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 60,
                        height: 60,
                        cursor: "pointer",
                        boxShadow: "0 6px 14px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.3s ease",
                        "&:hover": {
                            transform: "scale(1.1)",
                            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
                        },
                    }}
                    onClick={handleToggleView}
                >
                    <IconButton color="primary">
                        {view === 'first' ? <ViewListIcon fontSize="large" /> : <GridViewIcon fontSize="large" />}
                    </IconButton>
                </Box>
            </Box>

            {/* Lists Section */}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, flexWrap: "wrap", justifyContent: "center", mt: 5 }}>
                {view === 'first' ? (
                    <>
                        {/* New Feature Request */}
                        <Box sx={boxStyles}>
                            <FeatureRequests type="New Feature Request" icon={feature} />
                        </Box>

                        {/* Bug Report */}
                        <Box sx={boxStyles}>
                            <FeatureRequests type="Bug Report" icon={bug} />
                        </Box>
                    </>
                ) : (
                    <ThemeTableSection
                        paginatedData={paginatedData}
                        handleOpenDrawer={handleOpenDrawer}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        totalCount={totalCount}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ListHolder;
