import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Box } from "@mui/material";

const BarCharts = ({ data, title, colors = "" }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Simulate a delay to allow visibility detection
        const timeout = setTimeout(() => setIsVisible(true), 100);
        return () => clearTimeout(timeout);
    }, []);

    const options = {
        title: title || "Data Analysis",
        chartArea: { width: "70%" },
        isStacked: true,
        hAxis: {
            title: "Value",
            minValue: 0,
        },
        vAxis: {
            title: "Category",
        },
        colors: colors || ["#97dde8"],
        bar: {
            groupWidth: "70%",
        },
        legend: { position: "top" },
    };

    return (
        <Box sx={{ width: "100%", height: { xs: "300px", sm: "400px" } }}>
            {isVisible && (
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                />
            )}
        </Box>
    );
};

export default BarCharts;
