import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import { useFeedbackQContext } from '../context/FeedbackQ';
import Dashboard from './Dashboard';
import SkeletonDashboard from './utils/shimmerUI/SkeletonDashboard';
import { fetchDataSources, fetchDashboardData } from '../api/API';

// Reusable Component for Typography Blocks
const FeatureBlock = ({ title, description }) => (
    <Grid item xs={12} md={11}>
        <Typography variant="h5" component="p" sx={{ mb: 2, color: '#fff', textAlign: 'center' }}>
            <strong>{title}</strong>
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}>
            {description}
        </Typography>
    </Grid>
);

const TitleSection = () => {
    const { hasApps, setHasApps, dashboardCheck, analysisCheck, duration, source,  category,  filterSource, filterNpxChart} = useFeedbackQContext();
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    const location = useLocation()

    const isDashboardRoute = location.pathname.includes("/");

    React.useEffect(() => {
        // Only run the effect if the current route is the dashboard route
        if (isDashboardRoute) {
            const checkApps = async () => {
                setLoading(true);
                const dashboard_id = localStorage.getItem("dashboardId");
                const orgId = sessionStorage.getItem("orgId");

                if (!dashboard_id || !orgId) {
                    setLoading(false);
                    return;
                }
                try {

                    const fetchData = await fetchDataSources(dashboard_id);
                    
                    if (fetchData.datasources.length > 0) {
                        const response = await fetchDashboardData(orgId, dashboard_id, duration, source, category, filterNpxChart, filterSource);
                        console.log('response filters dashboard :: ', response);
                        setData(response);
                        setHasApps(true);
                    } else {
                        setHasApps(false);
                    }
                } finally{
                    setLoading(false)
                }
            };

            checkApps();
        }
    }, [isDashboardRoute, dashboardCheck, analysisCheck, setHasApps, duration, source, category, filterSource, filterNpxChart]);

    if (loading) {
        return <SkeletonDashboard />;
    }

    if (!hasApps) {
        return (
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    textAlign: 'center',
                    minHeight: '90vh',
                }}
            >
                <Paper
                    sx={{
                        p: { xs: 3, sm: 4, md: 5 },
                        backgroundColor: '#37517e',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        maxWidth: '800px',
                        width: '100%',
                    }}
                >
                    <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#fff', fontWeight: 700 }}>
                        FeedbackQ
                    </Typography>
                    <Typography
                        variant="h5"
                        component="p"
                        sx={{ mt: 2, mb: 6, color: '#ffffff99', fontSize: { xs: '20px', sm: '24px' }, fontWeight: 500 }}
                    >
                        Welcome to Your Feedback Engine Co-pilot!
                    </Typography>
                    <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                        {/* Reuse FeatureBlock Component for repeated content */}
                        <FeatureBlock
                            title="Empower Your Insights"
                            description="Unlock the potential of your feedback with AI-powered analysis and prioritization. Your journey to smarter decision-making starts here!"
                        />
                        <FeatureBlock
                            title="Seamless Integration"
                            description="Effortlessly manage and analyze feedback from multiple sources. Keep track of what's important and drive your product forward with data-driven insights."
                        />
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                component={Link}
                                to="/source"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#47b2e4',
                                    color: '#fff',
                                    borderRadius: 50,
                                    px: 4,
                                    py: 2,
                                    width: 200,
                                    '&:hover': { backgroundColor: '#3a9ec8' },
                                }}
                            >
                                Get Started
                            </Button>
                        </Grid>
                        <FeatureBlock
                            title=""
                            description="Navigate through our intuitive interface to begin categorizing, prioritizing, and making the most of your feedback. Your co-pilot is here to guide you every step of the way!"
                        />
                    </Grid>
                </Paper>
            </Box>
        );
    }

    return <Dashboard  data={data} themeCheck={false}/>;
};

export default TitleSection;
