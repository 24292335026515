import React, { useEffect, useState } from 'react';
import {
  Box, Button, Divider, Icon, Menu, MenuItem, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, IconButton, Paper, Select, FormControl, InputLabel,
  Pagination, Rating,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import playStore from '../assets/icons/game.png';
import appStore from '../assets/icons/appstore.png';
import apiIcon from '../assets/icons/api-icon.png';
import unknownIcon from '../assets/icons/unknown-mail.png';
import csv from '../assets/icons/csv-icon.png';
import ReviewTable from './snippets/ReviewTable';
import FeedbackFormDialog from './snippets/FeedbackFormDialog';
import DateRangeDialog from './snippets/DateRangeDialog'
import CancelIcon from '@mui/icons-material/Cancel';
import { analyzeReviews, createFeedback, fetchAllReviews } from '../api/API';
import SkeletonTable from './utils/shimmerUI/SkeletonTable';
import { format } from 'date-fns';
import { useFeedbackQContext } from '../context/FeedbackQ';
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewDrawer from './theme/ReviewDrawer';
import { formatReview } from '../helper/helper';
import { useNotification } from '../context/Notification';

const Snippet = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [newFeedback, setNewFeedback] = useState({
    userName: "",
    content: "",
    rating: "",
    source: "",
    project: "",
  });
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [sourceFilter, setSourceFilter] = useState("");
  const [ratingFilter, setRatingFilter] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [error, setError] = useState(null);
  const [totalReviews, setTotalReviews] = useState(0);
  const [spamCount, setSpamCount] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [category, setCategory] = useState("");
  const [openFullContent, setOpenFullContent] = useState(false);
  const [fullContent, setFullContent] = useState('');
  const {
    dashboardCheck,
    setIsAnalyzing,
    setProgressMessage,
    setIsDisabled,
  } = useFeedbackQContext();
  const { showNotification } = useNotification();
  const rowsPerPage = 14;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramCategory = query.get('category') || ''
  const navigate = useNavigate()
  useEffect(()=>{
    setPage(1);
  },[category, ratingFilter, sourceFilter, dateRange])

  useEffect(() => {
    if (paramCategory && paramCategory !== category) setCategory(paramCategory);
    const getReviews = async () => {
      setLoadingReviews(true);
      try {
        const dashboard_id = localStorage.getItem("dashboardId");
        const response = await fetchAllReviews(
          page,
          rowsPerPage,
          sourceFilter,
          ratingFilter,
          category,
          dateRange,
          dashboard_id
        );

        // console.log("response reviews :::", response)
        setTotalPages(response?.pagination?.total_pages || 1);
        setReviews(response?.reviews || []);
        setCurrentPage(response?.pagination?.current_page);
        setTotalReviews(response?.total_count || 0);
        setSpamCount(response?.spamCount || 0);
      } catch (error) {
        setError("Failed to fetch reviews");
      } finally {
        setLoadingReviews(false);
      }
    };

    getReviews();
  }, [page, sourceFilter, ratingFilter, category, dateRange, dashboardCheck]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (filter) => {
    setAnchorEl(null);
    setSelectedFilter(filter);

    if (filter === "Date range") {
      setOpenDateRange(true);
    }
  };

  const hasActiveFilters = () => {
    return (
      sourceFilter ||
      category ||
      ratingFilter ||
      dateRange.startDate ||
      dateRange.endDate
    );
  };

  const handleFormOpen = () => {
    setOpenForm(true);
  };

  const handleAnalyze = async () => {
    try {
      setIsDisabled(true);
      setIsAnalyzing(true);
      setProgressMessage(
        "Analysing reviews fetched from play store. Please wait..."
      );
      const dashboard_id = localStorage.getItem('dashboardId')
      const orgID = sessionStorage.getItem('orgId')
      const userId = sessionStorage.getItem('userId')
      const analyzeData = await analyzeReviews(dashboard_id, orgID, userId);

      const analysisMessage = analyzeData.data.message;
      if (analysisMessage === 'token_exhausted') {
        showNotification(
          "error",
          "Token exhausted. Please refresh the tokens"
        );
      }
      else {
        showNotification(
          "success",
          "Analysis done"
        );
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setIsAnalyzing(false);
      setIsDisabled(false);
    }
    
  }

  const handleFormClose = () => {
    setNewFeedback({
      userName: "",
      content: "",
      rating: "",
      source: "",
    });
    setOpenForm(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFeedback({ ...newFeedback, [name]: value });
  };

  const handleCreateFeedback = async () => {
    // console.log("New Feedback:", newFeedback);
    const dashboard_id = localStorage.getItem('dashboardId')
    const orgId = sessionStorage.getItem('orgId')
    const userId = sessionStorage.getItem('userId')
    const response = await createFeedback(newFeedback, dashboard_id, orgId);
    if (response.ok) {
      setNewFeedback({
        userName: "",
        content: "",
        rating: "",
        source: "",
      });


    } else {
      console.error("Failed to create feeback: ", response.statusText);
    }
    const analyzeData = await analyzeReviews(dashboard_id, orgId, userId);
    // console.log("Analysis completed:", analyzeData);
    setOpenForm(false);
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " ...";
    } else {
      return text;
    }
  };

  const handleContentClick = (content) => {
    // console.log("content:: ", content);
    setFullContent(content);
    setOpenFullContent(true);
  };

  const handleContentClose = () => {
    setOpenFullContent(false);
    setFullContent('')
  };

  const handleFilterReset = () => {
    setSourceFilter("");
    setRatingFilter("");
    setCategory("");
    setDateRange({
      startDate: null,
      endDate: null,
    });
    if (paramCategory) navigate('/reviews');
  }

  const applyDateRangeFilter = () => {
    setSelectedFilter("Date range");
    setOpenDateRange(false);
    setPage(1)
  };

  const handleCategoryClick = (category) => {
    setPage(1);
    setCategory(category);
  };

  return (
    <div className="w-full max-w-7xl h-full text-black mx-auto p-4 sm:p-6 md:p-1 box-border mb-2 md:mb-6">
      <Box className="container mx-auto p-4 rounded-lg">
        <Box className="w-full p-4 mx-2 bg-white shadow-sm rounded-md">
          <Paper className="sticky top-0 z-10 bg-white shadow-sm rounded-md">
            <Box className="flex justify-between items-center py-4 px-6">
              <Typography variant="h5" className="text-gray-800 font-semibold">
                {totalReviews} Feedback
              </Typography>
              <Button
                variant="outlined"
                onClick={() => handleCategoryClick('Spam')}
                className="text-gray-500 font-medium"
                sx={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#37517e',
                    color: 'whitesmoke'
                  }
                }}
              >
                Spams: {spamCount}
              </Button>
            </Box>
            <Divider />
            <Box className="flex justify-between items-center py-4 px-6">
              <Box className="flex items-center space-x-2">
                {/* <Typography variant="body1">06/02/2022 - 07/02/2024</Typography> */}
                <Button
                  variant="outlined"
                  sx={{
                    ml: 2,
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#37517e',
                      color: 'whitesmoke'
                    }
                  }}
                  startIcon={<FilterListIcon />}
                  onClick={handleFilterClick}
                >
                  Filter
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleFilterClose(null)}
                  PaperProps={{
                    style: {
                      width: "350px",
                    },
                  }}
                >
                  <MenuItem onClick={() => handleFilterClose("Source")}>
                    <FormControl fullWidth>
                      <InputLabel>Source</InputLabel>
                      <Select
                        value={sourceFilter}
                        onChange={(e) => setSourceFilter(e.target.value)}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="play_store">Play Store</MenuItem>
                        <MenuItem value="app_store">App Store</MenuItem>
                        <MenuItem value="csv">CSV</MenuItem>
                        <MenuItem value='api'>API</MenuItem>
                      </Select>
                    </FormControl>
                  </MenuItem>
                  <MenuItem onClick={() => handleFilterClose("Date range")}>
                    Date range
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleFilterClose("Rating")}
                    className="p-2"
                  >
                    <FormControl fullWidth>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        className="my-3"
                      >
                        <Rating
                          name="rating-filter"
                          value={ratingFilter || 0}
                          onChange={(event, newValue) =>
                            setRatingFilter(newValue)
                          }
                          size="small"
                          sx={{ fontSize: "1.25rem", mt: 1 }} // Adding margin-top to provide space between label and stars
                        />
                      </Box>
                    </FormControl>
                  </MenuItem>
                  <MenuItem>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="New Feature Request">
                          New Feature Requests
                        </MenuItem>
                        <MenuItem value="Bug Report">Bugs</MenuItem>
                        <MenuItem value="Spam">Spams</MenuItem>
                        <MenuItem value="Question">Questions</MenuItem>
                        <MenuItem value="Appreciation">Appreciations</MenuItem>

                      </Select>
                    </FormControl>
                  </MenuItem>
                </Menu>
                {hasActiveFilters() && (
                  <Button
                    variant="outlined"
                    sx={{
                      ml: 2,
                      color: 'white',
                      backgroundColor: '#37517e',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#37517e',
                      }
                    }}
                    startIcon={<CancelIcon />}
                    onClick={handleFilterReset}
                  >
                    Reset All
                  </Button>
                )}
              </Box>
              <Box className="w-[300px] flex justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-2 shadow-md hover:shadow-lg"
                  onClick={handleAnalyze}
                >
                  Analyze
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ml: 2,
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#37517e',
                      color: 'whitesmoke'
                    }
                  }}
                  onClick={handleFormOpen}
                  className="shadow-md hover:shadow-lg"
                >
                  + Feedback
                </Button>
              </Box>
            </Box>
          </Paper>
          <TableContainer className='mt-4 rounded-lg shadow-sm'>
            <Table stickyHeader>
              <TableHead sx={{ position: "sticky", top: 0 }} className='bg-gray-100'>
                <TableRow>
                  <TableCell />
                  <TableCell className="text-xs">Feedback</TableCell>
                  <TableCell className="text-xs">User</TableCell>
                  <TableCell className="text-xs">Date</TableCell>
                  <TableCell className="text-xs">Tags</TableCell>
                  <TableCell className="text-xs">Project</TableCell>
                  <TableCell className="text-xs">Rating</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingReviews ? (
                  <SkeletonTable rows={rowsPerPage} columns={6} />
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={8} className="text-center" sx={{ color: 'red' }}>
                      {error}
                    </TableCell>
                  </TableRow>
                ) : reviews.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} className="text-center">
                      <Typography variant="body2" color="textSecondary">
                        No Feedbacks Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  reviews.map((review, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                        cursor: "pointer"
                      }}
                      onClick={() => handleContentClick(review)}
                    >
                      <TableCell
                        sx={{
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icon sx={{ width: "24px", height: "24px" }}>
                          <img
                            src={
                              review.Source === "play_store"
                                ? playStore
                                : review.Source === 'app_store'
                                  ? appStore
                                  : review.Source === 'csv'
                                    ? csv
                                    : review.Source === 'api'
                                      ? apiIcon : unknownIcon
                            }
                            alt="app source"
                            className='w-5 h-5'
                          />
                        </Icon>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "8px",
                          minWidth: '200px', 
                        }}
                        className='text-xs'
                      >
                        <Typography
                          width="400px"
                          sx={{
                            cursor: "pointer",
                            fontFamily: 'Roboto, sans-serif',
                            fontWeight: 400,
                            lineHeight: '1.5rem',
                            color: 'black',
                            fontSize: '0.855rem'
                          }}
                          onClick={() => handleContentClick(review.Feedback)}
                          className='text-xs font-sans'
                        >
                          {truncateText(review.Feedback, 55)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "8px", fontSize: "0.855rem" }}>
                        {review.User || "-"}
                      </TableCell>
                      <TableCell sx={{ padding: "8px", fontSize: "0.855rem", textAlign: 'center' }}>
                        {review.Date
                          ? format(new Date(review.Date), "dd/mm/yyyy")
                          : "-"}
                      </TableCell>
                      <TableCell sx={{ padding: "8px", fontSize: "0.855rem", textAlign: 'center' }}>
                        <Typography variant="body2">
                          {review.Tags ? review.Tags.join(", ") : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "8px", fontSize: "0.855rem", textAlign: 'center' }}>
                        {review.Project || "-"}
                      </TableCell>
                      <TableCell sx={{ padding: "8px", fontSize: "0.855rem", textAlign: 'center' }}>
                        <Typography variant="body2">
                          {review.Rating || "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalPages > 1 && (
            <Box className="mt-4 flex justify-center">
              <Pagination
                count={totalPages}
                page={page}
                onChange={(e, value) => setPage(value)}
                color="primary"
              />
            </Box>
          )}
        </Box>

        {/* Dialog to show full feedback content */}
        {/* <Dialog open={openFullContent} onClose={handleContentClose} fullWidth maxWidth="sm">
                  <DialogTitle>Full Feedback Content</DialogTitle>
                  <DialogContent>
                      <Typography>{fullContent}</Typography>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleContentClose} color="primary">Close</Button>
                  </DialogActions>
              </Dialog>    */}

        <Dialog open={openForm} onClose={handleFormClose} fullWidth maxWidth="sm">
          <DialogTitle>
            Add Feedback
            <IconButton
              aria-label="close"
              onClick={handleFormClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="userName"
              label="User Name"
              type="text"
              fullWidth
              variant="outlined"
              value={newFeedback.userName}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="content"
              label="Feedback Content"
              type="text"
              fullWidth
              variant="outlined"
              value={newFeedback.content}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="rating"
              label="Rating"
              type="number"
              fullWidth
              variant="outlined"
              value={newFeedback.rating}
              onChange={handleInputChange}
            />
            {/* <TextField
                          margin="dense"
                          name="source"
                          label="Source"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={newFeedback.source}
                          onChange={handleInputChange}
                      />
                      <TextField
                          margin="dense"
                          name="project"
                          label="Project"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={newFeedback.project}
                          onChange={handleInputChange}
                      /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFormClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateFeedback} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <DateRangeDialog
          open={openDateRange}
          onClose={() => setOpenDateRange(false)}
          onApply={applyDateRangeFilter}
          dateRange={dateRange}
          onDateRangeChange={handleDateRangeChange}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
        />
        {fullContent && (
          <ReviewDrawer open={openFullContent} onClose={handleContentClose} review={formatReview(fullContent)} />
        )}
      </Box>
    </div>
  );
};

export default Snippet;
