import { format } from "date-fns";
import { Typography } from '@mui/material';

export const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const transformCardData = (data) => {
  if (!Array.isArray(data)) return [];

  return data.map((item) => {
    const key = Object.keys(item)[0];
    let heading = "";
    let content = 0;
    let sub = "";

    switch (key) {
      case "no_of_themes":
        heading = "Total Themes";
        content = item[key];
        break;
      case "no_of_new_feature_requests":
        heading = "New Feature Requests";
        content = item[key];
        sub = `${item.feature_percentage}% of Themes`;
        break;
      case "no_of_bugs":
        heading = "Bugs";
        content = item[key];
        sub = `${item.bugs_percentage}% of Themes`;
        break;
      case "no_of_spams":
        heading = "Spams";
        content = item[key];
        break;
      default:
        console.warn(`Unexpected key in theme card: ${key}`);
        break;
    }

    return { heading, content, sub };
  });
};

export const formatTopThemes = (data, maxLength = 50) => {
  if (!Array.isArray(data))
    return [
      ["Theme", "Count"],
      ["No data", 0],
    ];

  const header = ["Theme", "Count"];

  const truncateTitle = (title) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "...";
    }
    return title;
  };

  const result = data.map((item) => [
    truncateTitle(item.theme),
    item.review_count || 0,
  ]);

  return [header, ...result];
};

export const formatSentimentData = (backendData) => {
  if (!Array.isArray(backendData)) return [];

  const header = ["Sentiment Distribution", "Positive", "Negative", "Neutral"];

  const result = backendData.map((item, index) => {
    const month = Object.keys(item)[0];
    const { positive, negative, neutral } = item[month];
    return [month, positive || 0, negative || 0, neutral || 0];
  });

  return [header, ...result];
};

export const transformDataThemesReviewChart = (data) => {
  if (!data) return { data: [], title: "Feedback Count" };

  const { daily_count, weekly_count, monthly_count } = data;
  let dataArray;
  let title;

  if (daily_count.length > 0) {
    dataArray = daily_count;
    title = "Daily Feedback Count";
  } else if (weekly_count.length > 0) {
    dataArray = weekly_count;
    title = "Weekly Feedback Count";
  } else if (monthly_count.length > 0) {
    dataArray = monthly_count;
    title = "Monthly Feedback Count";
  } else {
    return { data: [["Date", "Review Count"]], title: "Feedback Count" };
  }

  return {
    data: [
      [title.split(" ")[0], "Review Count"],
      ...dataArray.map((item) => [item.date, item.review_count]),
    ],
    title,
  };
};

export const transformSentimentData = (data) => {
  if (!data) return [];

  const transformedData = [
    ["Sentiment", "Percentage"],
    ["Positive", data.positive || 0],
    ["Negative", data.negative || 0],
    ["Neutral", data.neutral || 0],
  ];

  return transformedData;
};

export const getAxisFormat = (chartTitle) => {
  if (chartTitle?.includes("Monthly")) {
    return "MMM yyyy";
  } else if (chartTitle?.includes("Weekly")) {
    return "Week W";
  } else {
    return "yyyy-MM-dd";
  }
};

export const transToxicityLevelData = (data) => {
  const { toxicityLevel_percentage } = data.toxicityLevel_percentage;
  return [
    ["Toxicity Level", "Percentage"],
    ...Object.entries(toxicityLevel_percentage).map(([key, value]) => [
      key,
      value,
    ]),
  ];
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}.slice(-2)`;
  }

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name[0]}`,
  };
}

export const defaultRules = [
  "Theme Summary",
  "Anomalies",
  "Dev Activities",
  "Action Items",
];

export const getCustomTemplate = () => {
  return [
    {
      defaultID: "T1A2024",
      title: "Service Feedback Trends Evaluation",
      content:
        "Evaluate trends in service feedback to improve our customer service approaches.",
      template: true,
      save: false,
    },
    {
      defaultID: "T2B2024",
      title: "Customer Retention Improvement",
      content:
        "Based on customer feedback, what changes can we make to improve customer retention?",
      template: true,
      save: false,
    },
    {
      defaultID: "T3C2024",
      title: "Demographic Feedback Comparison",
      content:
        "Compare feedback from different demographics to tailor our marketing messages.",
      template: true,
      save: false,
    },
    {
      defaultID: "T4D2024",
      title: "Product Launch Strategy",
      content:
        "Utilize customer feedback to inform the launch strategy of our upcoming product line.",
      template: true,
      save: false,
    },
    {
      defaultID: "T5E2024",
      title: "Root Cause Analysis of Service Outage",
      content:
        "Conduct a root cause analysis based on negative feedback received after a service outage.",
      template: true,
      save: false,
    },
    {
      defaultID: "T6F2029",
      title: "Feedback Loop Development",
      content:
        "Implement a system for developing a feedback loop with customers for continuous improvement.",
      template: true,
      save: false,
    },
    {
      defaultID: "T7G2024",
      title: "Personalized Customer Journeys",
      content:
        "How can we use feedback to personalize customer journeys in our e-commerce platform?",
      template: true,
      save: false,
    },
    {
      defaultID: "T8H2024",
      title: "Product Usability Issues Identification",
      content:
        "Identify and address product usability issues based on recent customer feedback.",
      template: true,
      save: false,
    },
    {
      defaultID: "T9I2024",
      title: "Theme Impact Evaluation",
      content:
        "Evaluate the potential impact of each theme on user satisfaction, product performance, and business metrics.",
      template: true,
      save: false,
    },
    {
      defaultID: "T10J2024",
      title: "SWOT Analysis of Customer Reviews",
      content:
        "Adapt SWOT Analysis to look at the below customer reviews. Then do a SWOT and summarise the data in text.",
      template: true,
      save: false,
    },
    {
      defaultID: "T11K2024",
      title: "Pareto Analysis of Personal Care & Hygiene Themes",
      content:
        "Using Pareto analysis show me the top 20% of all the themes about personal care & hygiene within the data.",
      template: true,
      save: false,
    },
    {
      defaultID: "T12L2024",
      title: "Pareto Analysis of Empathy and Care Themes",
      content:
        "Using Pareto analysis show me the top 20% of all the themes about empathy and care within the data.",
      template: true,
      save: false,
    },
    {
      defaultID: "T13M2024",
      title: "General Pareto Analysis of Themes",
      content:
        "Using Pareto analysis show me the top 20% of all the themes within the data below. Summarise the outcomes of your analysis into a table.",
      template: true,
      save: false,
    },
  ];
};

export const showbookmark = (defaultTemplates, savedIds) => {
  const validDefaultTemplates = Array.isArray(defaultTemplates)
    ? defaultTemplates
    : [];
  const validSavedIds = Array.isArray(savedIds) ? savedIds : [];

  return validDefaultTemplates.map((rule) => ({
    ...rule,
    save: validSavedIds.includes(rule.defaultID) ? true : rule.save,
  }));
};

export const replaceTemplateValues = (template, values) => {
    return template.replace(/\${(.*?)}/g, (match, p1) => values[p1] || match);
};

export const highlightNumbers = (text) => {
    // Add spans around numbers for styling
    return text.replace(/(\d+)/g, '<span class="number">$1</span>');
};

export const renderExplanation = (text) => {
    return text.split('\n').map((line, index) => {
        if (line.startsWith("**")) {
            // Heading Style
            return (
                <Typography key={index} variant="h6" className="font-semibold" sx={{ mb: 4}}>
                    {line.replace(/\*\*/g, '')}
                </Typography>
            );
        } else if (line.startsWith("- ")) {
            // List item style
            return (
                <Typography key={index} className="ml-4 mb-1 p-2">
                    {line}
                </Typography>
            );
        } else {
            // Normal paragraph style
            return (
                <Typography key={index} className="mb-1" sx={{ mb: 2}}>
                    {line}
                </Typography>
            );
        }
    });
};

export const formatReview = (data) => {
  return {
    id: data.id,
    review: data.Feedback || "No review provided",
    user: data.User || "Anonymous",
    date: data.Date ? format(new Date(data.Date), "d/M/yyyy") : "-",
    tags: data.Tags || "No tags",
    project: data.Project || "No project",
    ratings: data.Rating,
    source: data.Source || "Unknown",
    empathy_map: data.empathy_map || {}
  };
};

export const formatAskQResponse = (responses) => {

  // Ensure the responses array is valid
  if (Array.isArray(responses)) {
    return responses.map((response) => {
      // Check if response is an object and contains valid content or response
      if (typeof response === 'object') {
        // Prefer 'content' over 'response', if available
        if (response.content) {
          return response.content.replace(/\n/g, '\n\n');
        } else if (response.response) {
          return response.response.replace(/\n/g, '\n\n');
        }
      }

      // Return a default message if no valid content is found
      return 'No valid response data';
    }).join('\n\n');  // Join all responses with double newlines
  }

  // Handle the case where responses is a single object with 'response' or 'content'
  if (typeof responses === 'object') {
    if (responses.content) {
      return responses.content.replace(/\n/g, '\n\n');
    } else if (responses.response) {
      return responses.response.replace(/\n/g, '\n\n');
    }
  }

  // Default fallback if nothing matches
  return 'No valid response data';
};

export const isValidSentimentData = (data)=>{
  return data && data.length > 1 && Array.isArray(data[1]);
}

