import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const QuadrantCard = ({ section }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // console.log(" section : ", section)
    return (
        <>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {section?.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                        {section?.question}
                    </Typography>
                    {section?.data?.slice(0, 3).map((item, i) => {
                        // console.log(item); // For debugging
                        return (
                            <Typography key={i} variant="body2" paragraph>
                                {item === 'None'
                                    ? `No ${section?.title}`
                                    : `• ${item.sentence || ''}`}
                            </Typography>
                        );
                    })}
                    <Button size="small" onClick={handleClickOpen}>
                        More
                    </Button>
                </CardContent>
            </Card>

            {/* Dialog for showing full content */}
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{section?.title}</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="body1" gutterBottom>
                        {section?.question}
                    </Typography>
                    {section?.data?.map((item, i) => {
                        // console.log(item); // For debugging
                        return (
                            <Typography key={i} variant="body2" paragraph>
                                {item === 'None'
                                    ? `No ${section?.title}`
                                    : `• ${item.sentence || ''}`}
                            </Typography>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default QuadrantCard;
