import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { formatSentimentData } from "../helper/helper";
import { useFeedbackQContext } from "../context/FeedbackQ";
import { fetchThemeAndAnalysis } from "../api/API";
import FeatureList from "./SecondThemeView";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import CardGridSection from "./theme/secondview/CardGridSection";
import ChartCarousel from "./theme/ChartCarousel";

const Themes = () => {
    const [sentimentData, setSentimentData] = useState([]);
    const [view, setView] = useState("first");
    const [themeAnalysisData, setThemeAnalysisData] = useState({});
    const [themeAnalysis, setThemeAnalysis] = useState({})
    const {
        setThemeError,
        setAnalysisError,
        setLoadingThemes,
        setLoadingAnalysis,
        setThemes,
        dashboardCheck,
        loadingAnalysis,
        analysisError
    } = useFeedbackQContext();

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingThemes(true);
                setLoadingAnalysis(true);
                const dashboard_id = localStorage.getItem("dashboardId");
                const { themes: fetchedThemes, themeAnalysis: fetchedThemeAnalysis } =
                    await fetchThemeAndAnalysis(dashboard_id);
                setThemes(
                    fetchedThemes.sort((a, b) => b.reviews_count - a.reviews_count)
                );
                setThemeAnalysis(fetchedThemeAnalysis);
            } catch (error) {
                console.error("Error occurred while fetching data:", error);
                if (error.message.includes("themes")) {
                    setThemeError(error);
                } else {
                    setAnalysisError(error);
                }
            } finally {
                setLoadingThemes(false);
                setLoadingAnalysis(false);
            }
        };

        fetchData();
    }, [dashboardCheck]);

    useEffect(() => {
        if (themeAnalysis && themeAnalysis?.barChartData) {
            setSentimentData(formatSentimentData(themeAnalysis?.barChartData));
        }
    }, [themeAnalysis]);

    const handleToggleView = () => {
        setView(view === "first" ? "second" : "first");
    };

    useEffect(() => {
        if (!loadingAnalysis && !analysisError) {
            setThemeAnalysisData(themeAnalysis);
        }
    }, [themeAnalysis, loadingAnalysis, analysisError]);

    return (
        <div className="w-full max-w-[86%] h-full text-black mx-auto p-4 sm:p-6 md:p-8 box-border mb-2 md:mb-6">
            <Box className="flex border border-gray-300 rounded-lg p-4 mb-4">
                <Box className="flex-1">
                    <Typography variant="h6" component="div" gutterBottom>
                        Themes
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Actionable insights clustered for each category: feature, bug, customer question, appreciation. The report is updated automatically with new data.
                    </Typography>
                </Box>
            </Box>
            <CardGridSection cardData={themeAnalysisData.cardData} />
            <ChartCarousel sentimentData={sentimentData} themeAnalysis={themeAnalysis}/>
            <Box className="container mx-auto px-4 sm:px-6 md:px-8 mt-4">
                <Box sx={{ width: '100%' }}>
                    <FeatureList handleToggleView={handleToggleView} view={view} />
                    
                </Box>
            </Box>
        </div>


    );
};

export default Themes;
