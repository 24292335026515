import { Box, Drawer, Typography } from "@mui/material";
import Dashboard from "../Dashboard";


const ThemeAnalytics = ({ open, onClose, dashboardData }) => {
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '72%',
                    padding: '16px',
                },
            }}
        >
            <Box position="relative" p={4}>
                {dashboardData ? (
                    <Dashboard data={dashboardData} themeCheck={true} />
                ) : (
                    <Typography variant="body1">No review data available</Typography>
                )}
            </Box>
        </Drawer>
    );
}

export default ThemeAnalytics