import { Box, Typography } from '@mui/material';
import { formatTopThemes } from '../../helper/helper';
import { useFeedbackQContext } from '../../context/FeedbackQ';
import SkeltonGraph from '../utils/shimmerUI/SkeletonGraph';
import BarCharts from '../charts/BarCharts';


const TopFiveTheme = ({ themeAnalysis }) => {
    const { loadingAnalysis } = useFeedbackQContext();
    const chartData = formatTopThemes(themeAnalysis.topThemes)
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                padding: 3,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {loadingAnalysis ? (
                <SkeltonGraph />
            ) : !chartData ? (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f9f9f9',
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" align="center">
                        No top theme data available
                    </Typography>
                </Box>
            ) : (
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Typography
                        variant="h5"
                        sx={{ marginBottom: 2, textAlign: 'center', fontWeight: 'bold' }}
                    >
                        Top 5 Themes
                    </Typography>
                    <BarCharts
                        data={chartData}
                        title="Top 5 Themes"
                        colors={["#4caf50", "#f44336", "#2196f3", "#ffeb3b", "#9c27b0"]}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: 'top',
                                    labels: {
                                        font: {
                                            size: 14,
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};


export default TopFiveTheme;